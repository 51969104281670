import React from "react";
import styled from "styled-components";
import { Row, Col, Form } from "react-bootstrap";

import { FORM_FIELD_KEYS } from "../../../../constants/forms";
import { getFieldProps } from "../../../../utils/form-fields";
import { hexToRGBA } from "../../../../utils/theme";

import TextInput from "../../controls/TextInput";
import Textarea from "../../controls/Textarea";
import Button from "../../styled/Button";

const StyledContactUsForm = styled(Form)`
  button {
    width: 100%;
  }

  @media ${({ theme }) => theme.devices.tablet} {
  }
`;

const ContactUsForm = ({ values = {}, onChange, onSubmit }) => {
  return (
    <StyledContactUsForm noValidate validated={values.validated} onSubmit={onSubmit}>
      <TextInput
        {...getFieldProps(FORM_FIELD_KEYS.firstName)}
        label={undefined}
        placeholder="Name"
        disabled={values.loading}
        value={values[FORM_FIELD_KEYS.firstName]}
        onChange={onChange}
      />
      <TextInput
        {...getFieldProps(FORM_FIELD_KEYS.customerEmail)}
        label={undefined}
        placeholder="Email*"
        loading={values.loading}
        disabled={values.loading}
        value={values[FORM_FIELD_KEYS.customerEmail]}
        onChange={onChange}
      />
      <Textarea
        {...getFieldProps(FORM_FIELD_KEYS.message)}
        label={undefined}
        placeholder="Write Message"
        disabled={values.loading}
        value={values[FORM_FIELD_KEYS.message]}
        onChange={onChange}
      />

      <Row>
        <Col xs={0} md={3} />
        <Col xs={12} md={6}>
          <Button type="submit" disabled={values.loading}>
            Send Message
          </Button>
        </Col>
        <Col xs={0} md={3} />
      </Row>
    </StyledContactUsForm>
  );
};

export default ContactUsForm;
