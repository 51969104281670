import React from "react";

function ProductIcon(props) {
  const basePath = process.env.NEXT_PUBLIC_BASE_PATH;

  let image,
    imgClass = null;
  const { code } = props;
  if (!code) {
    return <div>loading</div>;
  } else {
    if (code === "EINSoleProp") image = `${basePath}/images/sole.png`;
    if (code === "EINLLC") image = `${basePath}/images/llc.png`;
    if (code === "EINTrust") image = `${basePath}/images/trust.png`;
    if (code === "EINEstate") {
      image = `${basePath}/images/estate.png`;
      imgClass = "estate";
    }
    if (code === "EINNonProfit") {
      image = `${basePath}/images/nonprofit.png`;
      imgClass = "nonprofit";
    }
    if (code === "EINChurch") {
      image = `${basePath}/images/church.png`;
      imgClass = "church";
    }
    if (code === "EINPartnership")
      image = `${basePath}/images/partherships.png`;
    if (code === "EINPSC") {
      image = `${basePath}/images/personal-service.png`;
      imgClass = "personalservice";
    }
    if (code === "EINCCorp") image = `${basePath}/images/corp.png`;
    if (code === "EINSCorp") {
      image = `${basePath}/images/s-corp.png`;
      imgClass = "scorp";
    }
  }
  return (
    <div className={"round-icon " + imgClass}>
      <img src={image} alt={image} />
    </div>
  );
}

export default ProductIcon;
