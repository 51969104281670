import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

import { hexToRGBA } from "../../../../utils/theme";

const { publicRuntimeConfig } = getConfig();

const StyledOurFeaturesSecondSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  .container {
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  }
  background: #FFFFFF;
  .ourFeatures {
    margin-bottom: 30px;
    padding: 8px 0;
    color: #fff;
    font-size: 18px;
    font-family: RobotoBold;
    background-color: #0097e6;
  }
  .ourFeatures span:before {
    position: relative;
    display: inline-block;
    top: -4px;
    margin-right: 12px;
    width: 15px;
    height: 9px;
    border-top: 4px solid #fff;
    border-right: 4px solid #fff;
    content: "";
    transform: rotate(135deg);
  }
  .ourFeatures--second {
    margin-bottom: 32px;
    background-color: #055393;
  }

  .ourFeatures span {
    position: relative;
    display: inline-block;
    padding: 4px 0;
    width: 100%;
    font-family: Myriad Pro, RobotoBold, sans-serif;
    text-align: left;
  }
  @media (min-width: 768px) {
    .ourFeatures span {
      width: 50%;
      padding: 0 40px;
      text-align: center;
      vertical-align: middle;
    }

    .ourFeatures span:first-child {
      border-right: 1px solid #80cbf3;
    }
    .ourFeatures--second span:first-child {
      border-right: 1px solid #82a9c9;
    }
  }
  
  @media (min-width: 1024px) {
    .ourFeatures {
      padding: 20px 0;
      font-size: 20px;
    }

    .ourFeatures--second {
      margin-bottom: 70px;
    }
  }
  @media (min-width: 768px) {
    .container {
      width: 750px;
    }
  }
  @media (min-width: 992px) {
    .container {
      width: 970px;
    }
  }

  @media (min-width: 1024px) {
    .container {
      width: 990px;
    }
  }
  @media (min-width: 1200px) {
    .container {
      width: 1170px;
    }
  }
  @media (min-width: 1280px) {
    .container {
      padding-left: 40px;
      padding-right: 40px;
      width: 1170px;
    }
  }
  @media (min-width: 1600px) {
    .container {
      width: 1440px;
    }
  }
`;

function OurFeaturesSecondSection() {
  return (
    <StyledOurFeaturesSecondSection>
      <div className="ourFeatures ourFeatures--second">
        <div className="container"><span>Talk to a trained EIN specialist if needed</span><span>Emailed to you in 60 minutes</span>
        </div>
      </div>
    </StyledOurFeaturesSecondSection>
  );
}

export default OurFeaturesSecondSection;
 
