import React, { useCallback, useEffect, useMemo, useState } from "react";
import styled, { css } from "styled-components";
import getConfig from "next/config";
import { isMobile } from "react-device-detect";
import { OverlayTrigger, Tooltip, Form, Spinner } from "react-bootstrap";

import { hexToRGBA } from "../../../../utils/theme";
import { usePrevious } from "../../../../hooks";
import xVerifyEmailService, {
  VALID_EMAIL_STATUSES,
  INVALID_EMAIL_STATUSES,
  VALIDATION_MESSAGE,
} from "../../../../../services/xVerifyEmailService";
import { checkEmail } from "../../../../../helpers/emailChecker";

const { publicRuntimeConfig } = getConfig();

const StyledGroup = styled(Form.Group)`
  display: flex;
  flex-direction: column;

  position: relative;

  padding-left: 0;
  padding-right: 0;
  padding-bottom: 16px;

  ${({ hideDivider }) =>
    hideDivider &&
    css`
      padding-bottom: 0;
    `};
`;

const StyledLabel = styled(Form.Label)`
  display: flex;
  align-items: center;

  width: 100% !important;

  font-family: NoticiaText;
  font-weight: 400;
  font-size: 14px;
  line-height: calc(1.42857rem * (14 / 16));
  color: #424242;

  span {
    color: #790000;
  }

  a {
    color: #b1b1b1;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #0a578b;
    }
  }

  ${({ emptyLabel }) =>
    emptyLabel &&
    css`
      padding-top: 18.56px;
    `};
`;

const StyledControl = styled(Form.Control)`
  min-height: 34px;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 4px;
  padding-right: 4px;

  font-family: NoticiaText;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #424242;

  border: 1px solid ${hexToRGBA("#000000", 0.1)};
  border-radius: 0;

  ${({ isValid }) =>
    isValid &&
    css`
      border: 1px solid #198754 !important;
      background-image: ${`url(${publicRuntimeConfig.basePath}/icons/V2/validation_success.svg)`} !important;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
      box-shadow: 0 0 0 0.2rem rgb(40 167 69 / 25%) !important;
    `};

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      border: 1px solid #dc3545 !important;
      background-image: ${`url(${publicRuntimeConfig.basePath}/icons/V2/validation_failure.svg)`} !important;
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
      box-shadow: 0 0 0 0.2rem rgb(220 53 69 / 25%) !important;
    `};

  ${({ isLoading }) =>
    isLoading &&
    css`
      border: 1px solid ${hexToRGBA("#234e76", 0.1)} !important;
      box-shadow: none !important;
      background-image: none !important;
    `};
`;

const StyledSpinner = styled(Spinner)`
  position: absolute;
  top: calc((34px - 2rem) / 2 + 18.52px);
  right: 16px;

  &.text-custom {
    color: ${hexToRGBA("#424242", 0.5)};
  }

  ${({ noLabel }) =>
    noLabel &&
    css`
      top: calc((34px - 2rem) / 2);
    `};
`;

const StyledFeedback = styled(Form.Control.Feedback)`
  &.processing-feedback,
  &.valid-feedback,
  &.invalid-feedback {
    font-size: 13px;
    line-height: calc(1.42857rem * (13 / 16));
  }

  &.processing-feedback {
    color: ${hexToRGBA("#424242", 0.5)};
  }

  &.valid-feedback {
    color: #198754;
  }

  &.invalid-feedback {
    color: #dc3545;
  }
`;

function TextInput({
  id,
  type,
  hint,
  label,
  loading,
  placeholder,
  emptyLabel,
  required,
  pattern,
  maxLength,
  defaultInvalidMessage = "",
  validate,
  isValid,
  isInvalid,
  onKeyPress,
  matchValue,
  isCorpAddress,
  value = "",
  onChange,
  onBlur,
  onKeyDown,
  onMouseEnter,
  onMouseLeave,
  allowSuccessMessage,
  hideDivider,
  thirdPartyProps,
  ...restProps
}) {
  const [verificationResult, setVerificationResult] = useState(null);
  const prevValue = usePrevious(value);

  const verifyEmail = useCallback(
    (value) => {
      if (!verificationResult && checkEmail(value)) {
        onChange && onChange({ target: { id: "verifying", value: true } });

        let emailValue = value;
        if (emailValue.indexOf("+") > -1 && emailValue.indexOf("gmail.com") > -1) {
          emailValue =
            emailValue.substring(0, emailValue.indexOf("+")) +
            emailValue.substring(emailValue.indexOf("@"), emailValue.length);
        }
        xVerifyEmailService(emailValue).then((result) => {
          setVerificationResult(result);
          onChange && onChange({ target: { id: "verifying", value: false } });
        });
      }
    },
    [onChange, verificationResult]
  );

  const handleChange = useCallback(
    (event) => {
      setVerificationResult(null);
      validate && validate(event, { required, matchValue, isCorpAddress });
      onChange && onChange(event);
    },
    [onChange, validate, required, isCorpAddress, matchValue]
  );

  const handleBlur = useCallback(
    (event) => {
      if (type === "email" && value) {
        verifyEmail(value);
      }

      onBlur && onBlur(event);
    },
    [onBlur, type, value, verifyEmail]
  );

  const handleKeyDown = useCallback(
    (event) => {
      if (type === "email" && value && event.code === "Enter") {
        if (!verificationResult && checkEmail(value)) {
          onChange && onChange({ target: { id: "suspendedSubmit", value: true } });
        }

        verifyEmail(value);
      }

      onKeyDown && onKeyDown(event);
    },
    [onKeyDown, onChange, type, value, verificationResult, verifyEmail]
  );

  const handleMouseEnter = useCallback(() => {
    onMouseEnter && onMouseEnter({ label: label || placeholder, hint });
  }, [onMouseEnter, label, placeholder, hint]);

  const overlayTriggerProps = useMemo(() => {
    const props = {
      placement: isMobile ? "top" : "right-start",
      delay: { show: 0, hide: 0 },
      trigger: ["hover", "focus"],
      overlay: (
        <Tooltip id={`tooltip-${id}`} className="hintTooltip">
          {hint}
        </Tooltip>
      ),
      show: false,
    };

    return props;
  }, [id, hint]);

  const valid =
    verificationResult?.status && !loading ? VALID_EMAIL_STATUSES.includes(verificationResult?.status) : isValid;
  const invalid =
    verificationResult?.status && !loading ? INVALID_EMAIL_STATUSES.includes(verificationResult?.status) : isInvalid;
  const validMessage = VALIDATION_MESSAGE[verificationResult?.reason] || "Verified!";
  const invalidMessage = VALIDATION_MESSAGE[verificationResult?.reason] || defaultInvalidMessage;
  const showInvalidMessage = !(valid && !invalid);

  const dynamicProps = useMemo(() => {
    if (thirdPartyProps) {
      if (onKeyPress) {
        return { ...thirdPartyProps, onKeyPress };
      }

      return thirdPartyProps;
    }

    const props = {
      type: type || "text",
      name: id,
      onChange: handleChange,
      onBlur: handleBlur,
      onKeyDown: handleKeyDown,
    };

    if (onKeyPress) {
      props.onKeyPress = onKeyPress;
    }

    return props;
  }, [thirdPartyProps, id, type, onKeyPress, handleChange, handleBlur, handleKeyDown]);

  useEffect(() => {
    if (type === "email" && prevValue === undefined && value) {
      verifyEmail(value);
    }
  }, [verifyEmail, type, prevValue, value]);

  return (
    <OverlayTrigger {...overlayTriggerProps}>
      <StyledGroup controlId={id} hideDivider={hideDivider} onMouseEnter={handleMouseEnter} onMouseLeave={onMouseLeave}>
        <StyledLabel emptyLabel={emptyLabel}>
          {label}
          {label && required && <span>&nbsp;*</span>}
        </StyledLabel>
        <StyledControl
          {...dynamicProps}
          {...restProps}
          required={required}
          placeholder={placeholder}
          isValid={valid}
          isInvalid={invalid}
          isLoading={loading}
          pattern={pattern}
          maxLength={maxLength}
          value={value || ""}
          data-hj-allow
        />
        {loading && type === "email" && <StyledFeedback type="processing">Verification...</StyledFeedback>}
        {allowSuccessMessage && !showInvalidMessage && !loading && (
          <StyledFeedback type="valid">{validMessage}</StyledFeedback>
        )}
        {showInvalidMessage && invalidMessage && !loading && (
          <StyledFeedback type="invalid">{invalidMessage}</StyledFeedback>
        )}
        {loading && <StyledSpinner animation="border" variant="custom" noLabel={!(label || emptyLabel)} />}
      </StyledGroup>
    </OverlayTrigger>
  );
}

export default TextInput;
