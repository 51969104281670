import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const StyledBenefitsSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;

  padding-top: 74px;
  padding-bottom: 64px;

  .benefit-item {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 330px;
    width: 33%;
    margin-left: 24px;
    margin-right: 24px;

    .image {
      margin-bottom: 32px;
    }

    .title {
      margin-bottom: 8px;
      font-family: 'MerriweatherLight';
      font-size: 18px;
      line-height: 28px;
      color: #078FF1;
    }

    .description {
      font-family: 'LatoRegular';
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #1B3858;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding-top: 14px;
    padding-bottom: 48px;

    .benefit-item {
      width: 100%;
      margin-top: 60px;
    }
  }
`;

function BenefitsSection() {
  return (
    <StyledBenefitsSection>
      <div className="benefit-item">
        <div className="image">
          <img
            src={`${publicRuntimeConfig.basePath}/images/V2/filing.jpg`} 
            alt="Quick, Accurate Filing" 
          />
        </div>
        <div className="title">
          <span>Quick, Accurate Filing</span>
        </div>
        <div className="description">
          <span>
            We’ll handle all of the regulations and red tape to ensure the whole process is as painless as possible and give you peace of mind. 
            We’ll ensure your EIN application is received in a timely manner and is completed accurately.
          </span>
        </div>
      </div>
      <div className="benefit-item">
        <div className="image">
          <img
            src={`${publicRuntimeConfig.basePath}/images/V2/attentive_service.jpg`} 
            alt="Attentive Service" 
          />
        </div>
        <div className="title">
          <span>Attentive Service</span>
        </div>
        <div className="description">
          <span>
            Our team of experts has years of experience dealing with Tax ID applications and working with the IRS. 
            We’ll inform you of anything missing from your application, and how to find it. 
            We’ll correct any errors so you don’t have to worry.
          </span>
        </div>
      </div>
      <div className="benefit-item">
        <div className="image">
          <img
            src={`${publicRuntimeConfig.basePath}/images/V2/security.jpg`} 
            alt="Security" 
          />
        </div>
        <div className="title">
          <span>Security</span>
        </div>
        <div className="description">
          <span>
            Rest assured that your private information is secure. 
            We guarantee your personal information is safe, and secure.
          </span>
        </div>
      </div>
    </StyledBenefitsSection>
  );
}

export default BenefitsSection;
