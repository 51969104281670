import React from "react";
import styled from "styled-components";
import Link from "next/link";

import LinkButton from "../../styled/LinkButton";
import { config } from "../../../../../../_config";

const { email } = config;
const StyledEntitiesCustomerSupportSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-bottom: 5rem;

  background: #FFFFFF;

  .entities-customer-support-section-title {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 40px;
    padding-bottom: 28px;
    margin-bottom: 30px;
    background-color: #000854;

    span {
      display: block;
      width: 980px;
      padding-left: 15px;
      padding-right: 15px;
      font-family: 'RobotoBold';
      font-size: 28px;
      line-height: calc(1.42857rem * (28 / 16));
      color: #FFFFFF;
    }
  }

  .entities-customer-support-section-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 980px;

    padding-left: 15px;
    padding-right: 15px;

    .entities-customer-support-section-hint {
      margin-bottom: 8px;
      font-family: 'RobotoBold';
      font-size: 26px;
      line-height: calc(1.42857rem * (26 / 16));
      text-align: center;
    }

    .entities-customer-support-section-text {
      margin-top: 8px;
      margin-bottom: 8px;
      text-align: center;
    }

    .entities-customer-support-section-action {
      margin-top: 8px;
      margin-bottom: 8px;

      a {
        font-size: 22px;
        line-height: calc(1.42857rem * (22 / 16));
        padding: 0.17rem 1.5rem !important;
      }
    }
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    .entities-customer-support-section-title {
      span {
        width: 720px;
      }
    }

    .entities-customer-support-section-container {
      width: 720px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-bottom: 4rem;

    .entities-customer-support-section-title {
      span {
        width: 100%;
      }
    }

    .entities-customer-support-section-container {
      align-items: center;
      width: 100%;
    }
  }
`;

function EntitiesCustomerSupportSection() {
  return (
    <StyledEntitiesCustomerSupportSection>
      <div className="entities-customer-support-section-title">
        <span>
          Customer Support
        </span>
      </div>
      <div className="entities-customer-support-section-container">
        <div className="entities-customer-support-section-hint">
          <span>
            Our support team is ready to assist with your application to get you on your way
          </span>
        </div>
        <div className="entities-customer-support-section-text">
          <span>
            Use the form below to submit a support request
          </span>
        </div>
        <div className="entities-customer-support-section-action">
          <Link href="/contactus">
            <LinkButton>
              Contact Form
            </LinkButton>
          </Link>
        </div>
        <div className="entities-customer-support-section-text">
          <span>
            Representatives are available Monday through Friday, 9 AM - 5 PM PST
          </span>
        </div>
        <div className="entities-customer-support-section-text">
          <span>
            If you need additional support, E-mail us at&nbsp;
            <a href={`mailto:${email}?subject=Mail from Contact Us USA Taxid Site`}>
                      {email}</a>&nbsp;
            or visit <Link href="/contactus"><a>Contact Us</a></Link>.
          </span>
        </div>
      </div>
    </StyledEntitiesCustomerSupportSection>
  );
}

export default EntitiesCustomerSupportSection;
