import React from "react";
import styled from "styled-components";
import { config as publicRuntimeConfig } from "../../../../../../_config";

const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 80px;
  padding-left: 80px;
  padding-right: 80px;
  

  .section-content-container {
    display: flex;
    justify-content: flex-start;
    width: 80%;
    padding: 50px;
    gap: 80px;
    .section-content-column2 {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border: 1px solid #000000;
      background: #EBF6FF;
      box-shadow: 0px 4px 4px 0px #00000040;
      padding-top: 40px;
      padding-bottom: 40px;
      padding-left: 8px;
      padding-right: 8px;
      .section-content-column-title {
        font-family: Platypi;
        font-size: 28px;
        font-weight: 700;
        line-height: 38.53px;
        text-align: center;
        margin-bottom: 8px;
        white-space: nowrap;
      }
      .list-header {
        font-family: Platypi;
        white-space: nowrap;
        font-size: 18px;
        font-weight: 700;
        line-height: 24.57px;
        text-align: center;
        margin-top: 0px;
        margin-bottom: 24px;
      }
      ul {
        list-style: disc;
        text-align: center;
        list-style-position: inside;
        font-family: Platypi;
        font-size: 18px;
        font-weight: 700;
        line-height: 25px;
      }
      .section-content-column-text {
        margin-bottom: 4px;
        font-family: NoticiaText;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        padding-right: 40px;
      }

      .section-content-column-subtext {
        margin-bottom: 4px;
        font-family: NoticiaText;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        text-align: right;
        padding-right: 40px;
      }

      .section-content-column-message {
        padding-top: 32px;
        font-family: NoticiaText;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #07437a;
      }

    }
    .section-content-column {
      width: 100%;
      color: #ffffff;
      align-items: center;
      display: flex;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    .section-title {
      margin-bottom: 24px;
      font-size: 38px;
      line-height: 42px;
    }

    .section-content-container {
      flex-direction: column;
      gap: 32px;
      width: 100%;
      padding: 16px;
      img {
        width: 100%;
      }
      .divider {
        height: 1px;
        width: 100%;
        border: 1px solid #ffffff;
      }
      .section-content-column2 {
        width: 100%;
      }
      .section-content-column {
        width: 100%;

        &:last-child {
          padding-left: unset;
        }

        .section-content-column-title {
          margin-bottom: 16px;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }
`;

const StyledButton = styled.div`
  background: #086BB2;
  box-shadow: 0px 4px 4px 0px #00000040;
  border: 2px solid #000000;
  white-space: nowrap;
  color: #FFFFFF;
  font-family: Platypi;
  font-size: 24px;
  font-weight: 700;
  line-height: 33.02px;
  text-align: left;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 36px;
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
    font-size: 14px;
  }
` ;
const StartFilingSection = () => {
  return (
    <StyleOuterContainer>
      <StyledInnerContainer>
        <div className="section-content-container">
          <div className="section-content-column2">
            <div className="section-content-column-title"><u>2024 Tax Services</u></div>
            <div className="list-header"><u>Get Ahead of things, started filing.</u></div>
            <ul><li>LLCs</li>
              <li>Sole Proprietors</li>
              <li>Corporations</li>
              <li>Non Profits</li>
              <li>Church Organizations</li>
            </ul>
          <a href="https://turbotax.intuit.com/personal-taxes/online/live/full-service/business-taxes/" target="_blank">
            <StyledButton>START FILING</StyledButton>
          </a>
          </div>
          <div className="section-content-column">
            <picture>
              <source
                srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/start.png`}
                type="image/png"
              />
              <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/start.png`} alt="" />
            </picture>
          </div>
        </div>
      </StyledInnerContainer>
    </StyleOuterContainer>
  );
};

export default StartFilingSection;
