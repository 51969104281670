import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    z-index: -1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    .background-image {
      top: -1px;
      bottom: -1px;
    }
  }
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  min-height: 888px;

  .section-title {
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: "Aeonik";
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    color: #ffffff;
  }

  .section-text {
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: "Aeonik";
    font-weight: 400;
    font-size: 24px;
    line-height: 27px;
    text-align: center;
    color: #ffffff;

    b {
      font-weight: 700;
    }
  }

  a {
    display: block;
    margin-top: 24px;
    margin-bottom: 24px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 52px;
    padding-right: 52px;
    border-radius: 5px;
    background: #ffffff;
    font-family: "Aeonik";
    font-weight: 700;
    font-size: 28px;
    line-height: 32px;
    text-align: center;
    color: #000000;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    min-height: unset;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;

    .section-title {
      margin-bottom: 8px;
      font-size: 38px;
      line-height: 42px;
    }

    .section-text {
      font-size: 16px;
      line-height: 20px;
    }

    a {
      display: block;
      margin-top: 16px;
      margin-bottom: 16px;
      padding-left: 16px;
      padding-right: 16px;
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

const StartFilingSection = () => {
  return (
    <StyleOuterContainer>
      <StyledInnerContainer>
        <div className="section-title">2023 Online Tax Filing Service</div>
        <div className="section-text">
          <b>IRS Authorized Electronic Return Originator.</b> Small Business Online Tax Filing{" "}
        </div>
        <a href="https://turbotax.intuit.com/personal-taxes/online/live/full-service/business-taxes/" target="_blank">
          Start E-Filing Your 2023 Return
        </a>
        <div className="section-text">
          <b>Filing 2023 Taxes Now. Get Yours Done Before the Extension Deadline.</b>
        </div>
      </StyledInnerContainer>
      <div className="background-image">
        <picture>
          <source srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/background.png`} type="image/png" />
          <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/background.png`} alt="Background Image" />
        </picture>
      </div>
    </StyleOuterContainer>
  );
};

export default StartFilingSection;
