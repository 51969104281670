import React from "react";

function SingleFAQ(props) {

  return (
    <React.Fragment>
      <p className="link-wrapper">
        <a
          data-bs-toggle="collapse"
          href={"#collapse" + props.count}
          role="button"
          aria-expanded="false"
          aria-controls={"collapse" + props.count}
          className="faq-link collapsed"
        >
          {props.q}
        </a>
      </p>
      <div className="collapse copy-wrapper" id={"collapse" + props.count}>
        <p>{props.a}</p>
      </div>
      <hr />
    </React.Fragment>
  );
}

export default SingleFAQ;
