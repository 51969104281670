import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const StyledHomeHeaderSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;

    top: 0;
    right: 0;

    height: 100%;
    width: 100%;

    background: ${`url(${publicRuntimeConfig.basePath}/images/V2/office_desk.jpg) no-repeat`};
    background-position-x: right;
    background-position-y: center;
    background-size: cover;
    z-index: -1;
  }

  .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 55%;
    padding-top: 50px;
    padding-bottom: 30px;
    padding-left: 8%;

    .header-title {
      margin-bottom: 32px;
      font-family: 'MerriweatherBold';
      font-size: 42px;
      line-height: 68px;
      text-transform: capitalize;
      text-align: center;
      color: #C61F0C;
      text-shadow: 1px 1px 0px #FFFFFF;
    }

    .header-text {
      margin-bottom: 32px;
      font-family: 'MerriweatherLight';
      font-size: 16px;
      line-height: 26px;
      text-align: center;
      color: #000000;
    }
  }

  @media ${({ theme }) => theme.devices.laptopM} {
    .header-content {
      width: 50%;
      padding-top: 40px;
      padding-bottom: 20px;
      padding-left: 4%;

      .header-title {
        margin-bottom: 28px;
        font-size: 36px;
        line-height: 62px;
      }

      .header-text {
        margin-bottom: 24px;
      }
    }
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    .header-content {
      .header-title {
        font-size: 32px;
        line-height: 58px;
      }
      .header-text {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    &::before {
      height: 165px;
      z-index: 0;
    }

    .header-content {
      width: 100%;

      padding-top: 185px;
      padding-bottom: 0;
      padding-left: 20px;
      padding-right: 20px;

      background: #F2F0F0;

      .header-title {
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 33px;
      }

      .header-text {
        margin-bottom: 20px;
        font-size: 12px;
        line-height: 22px;
      }
    }
  }
`;

function HomeHeaderSection() {
  return (
    <StyledHomeHeaderSection>
      <div className="header-content">
        <div className="header-title">
          <span>
            Apply for Your Employer Identification Number (EIN)
          </span>
        </div>
        <div className="header-text">
          <span>
            An Employer Identification Numbers (EIN) or Tax Identification Number (Tax ID, or TIN) 
            is required for those who want to form a business, 
            create a Trust or an Estate in the United States.
          </span>
        </div>
        <div className="header-text">
          <span>
            Select your entity and fill out the online Tax ID application to obtain an EIN number 
            in 4 hours to 4 business days depending on the delivery method you choose.
          </span>
        </div>
      </div>
    </StyledHomeHeaderSection>
  );
}

export default HomeHeaderSection;
