import React, { Component } from "react";
import {
  Container,
  OverlayTrigger,
  Spinner,
  Nav,
  Tooltip,
} from "react-bootstrap";
import ProductIcon from "./ProductIcon";
import Link from "next/link";
import ProductIconSkin from "./ProductIconSkin";
import { isMobile } from "react-device-detect";

const skin = process.env.NEXT_PUBLIC_REACT_APP_SKIN;
const basePath = process.env.NEXT_PUBLIC_BASE_PATH;

class Products extends Component {
  componentDidMount() {
    if (localStorage.getItem("crmSessionId")) {
      localStorage.removeItem("crmSessionId");
    }
  }
  render() {
    const products = [
      {
        code: "EIN_SoleProp",
        category: "EIN",
        customerName: "Sole Proprietor",
        forSale: true,
      },
      {
        code: "EIN_LLC",
        category: "EIN",
        customerName: "Limited Liability Company (LLC)",
        forSale: true,
      },
      {
        code: "EIN_Partnership",
        category: "EIN",
        customerName: "Partnerships",
        forSale: true,
      },
      {
        code: "EIN_CCorp",
        category: "EIN",
        customerName: "Corporations",
        forSale: true,
      },
      {
        code: "EIN_SCorp",
        category: "EIN",
        customerName: "S-Corporations",
        forSale: true,
      },
      {
        code: "EIN_Trust",
        category: "EIN",
        customerName: "Trusts",
        forSale: true,
      },
      {
        code: "EIN_Estate",
        category: "EIN",
        customerName: "Estate",
        forSale: true,
      },
      {
        code: "EIN_PSC",
        category: "EIN",
        customerName: "Personal Service Corporation",
        forSale: true,
      },
      {
        code: "EIN_NonProfit",
        category: "EIN",
        customerName: "Non-Profit Organization",
        forSale: true,
      },
      {
        code: "EIN_Church",
        category: "EIN",
        customerName: "Church Organization",
        forSale: true,
      },
    ];

    if (!products || products.length === 0) {
      return (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" />
        </div>
      );
    }

    return (
      <Container fluid className="products">
        {products.map((u) => (
          <React.Fragment key={u.code.replace("_", "")}>
            <OverlayTrigger
              placement={isMobile ? "top" : "right"}
              key={u.code.replace("_", "")}
              delay={{ show: 0, hide: 0 }}
              trigger={['hover', 'focus']}
              overlay={
                <Tooltip id={`tooltip-${u.customerName}`}>
                  <strong>{u.customerName}</strong>
                  <br />
                  {u.code.replace("_", "") === "EINSoleProp"
                    ? "A sole proprietor is someone who owns an unincorporated business by himself or herself."
                    : ""}
                  {u.code.replace("_", "") === "EINPartnership"
                    ? "A partnership is an arrangement between two or more individuals to create and manage a business. There are many types of partnerships, run in a variety of ways."
                    : ""}
                  {u.code.replace("_", "") === "EINCCorp"
                    ? "A corporation is popular as it offers liability protection, as well as separates the business owner and business as taxable entities."
                    : ""}
                  {u.code.replace("_", "") === "EINLLC"
                    ? "LLCs are the most common business entity in the U.S., offering flexibility, limited liability protection, and taxation advantages."
                    : ""}
                  {u.code.replace("_", "") === "EINSCorp"
                    ? "A type of corporation that draws its name from Subsection S of the tax code. It has all the features of a corporation, but also functions as a pass-through entity for taxation purposes."
                    : ""}
                  {u.code.replace("_", "") === "EINTrust"
                    ? "A type of legal entity in which an individual can place their assets so that they can be passed down to beneficiaries in the future."
                    : ""}
                  {u.code.replace("_", "") === "EINEstate"
                    ? "An estate is the sum of any assets left by a deceased individual. That includes things like property, vehicles, and valuables. The will of said individual will determine the administrator of their estate."
                    : ""}
                  {u.code.replace("_", "") === "EINPSC"
                    ? "A PSC is a subset of corporations that provides services specifically to individuals. These can include professions like healthcare, law, finance, and more."
                    : ""}
                  {u.code.replace("_", "") === "EINNonProfit"
                    ? "This entity is used by charities who donate their revenue to a specific goal or cause with the goal of benefiting the public instead of generating profit."
                    : ""}
                  {u.code.replace("_", "") === "EINChurch"
                    ? "A form of nonprofit that is specifically for religious organizations. And though it is called a “church organization,” it also includes places of worship such as mosques, temples, and synagogues."
                    : ""}
                </Tooltip>
              }
            >
              <Nav.Link
                className={
                  u.code.replace("_", "") === "EINSoleProp" ||
                  u.code.replace("_", "") === "EINLLC"
                    ? "popular"
                    : ""
                }
              >
                <Link href={`/products/${u.code.replace("_", "")}`} passHref>
                  <div
                    className={
                      skin === "taxid" || skin === "taxid-ein" ? "taxid-product product" : "product"
                    }
                  >
                    {skin === "taxid" || skin === "taxid-ein" ? null : (
                      <ProductIcon code={u.code.replace("_", "")} />
                    )}
                    {skin === "taxid" || skin === "taxid-ein" ? (
                      <ProductIconSkin code={u.code.replace("_", "")} />
                    ) : null}
                    <div className="description ">
                      <span
                        className={
                          u.customerName === "Sole Proprietor" ||
                          u.customerName === "S-Corporations"
                            ? "w-300"
                            : u.customerName === "Personal Service Corporation"
                            ? "w-350"
                            : ""
                        }
                      >
                        {u.customerName}
                      </span>
                      <p>
                        {u.code.replace("_", "") === "EINSoleProp"
                          ? "Select if you have no employees AND have NOT filed incorporation documentation with your state"
                          : ""}
                        {u.code.replace("_", "") === "EINLLC"
                          ? "Select if you have already setup a legal LLC entity"
                          : ""}
                        {u.code.replace("_", "") === "EINPartnership"
                          ? "Select if you are organized as a General partnership - Limited partnership (LP) - Limited liability partnership (LLP) - Limited liability limited partnership (LLLP)"
                          : ""}
                        {u.code.replace("_", "") === "EINCCorp"
                          ? "Select if you have already setup a legal C-Corp entity"
                          : ""}
                        {u.code.replace("_", "") === "EINSCorp"
                          ? "Select if you have already setup a legal S-Corp entity"
                          : ""}
                        {u.code.replace("_", "") === "EINTrust"
                          ? "A trust is a legal entity in which one party holds assets for the benefit of another"
                          : ""}
                        {u.code.replace("_", "") === "EINEstate"
                          ? "Select if your legal entity was created as a result of a person’s death"
                          : ""}
                        {u.code.replace("_", "") === "EINPSC"
                          ? "Select if you have already setup a Personal Service Corporation (PSC) entity"
                          : ""}
                        {u.code.replace("_", "") === "EINNonProfit"
                          ? "Select if your organization qualifies for tax-exempt status"
                          : ""}
                        {u.code.replace("_", "") === "EINChurch"
                          ? "Select if your organization is part of an organized religion, and is formally organized as a distinct legal entity"
                          : ""}
                      </p>
                      {(skin === "taxid" || skin === "taxid-ein") && (
                        <img
                          src={`${basePath}/images/right-arrow.png`}
                          alt="right-arrow"
                        />
                      )}
                    </div>
                  </div>
                </Link>
              </Nav.Link>
            </OverlayTrigger>
          </React.Fragment>
        ))}
      </Container>
    );
  }
}

export default Products;
