import React, { useMemo } from "react";
import styled from "styled-components";

import CollapsibleList from "../../CollapsibleList";

const StyledEntitiesDescriptionSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 3rem;

  .entities-description-title {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 24px;
    background-color: #244f74;

    span {
      display: block;
      width: 980px;
      padding-left: 15px;
      padding-right: 15px;
      font-family: 'MerriweatherBold';
      font-size: 28px;
      line-height: calc(1.42857rem * (28 / 16));
      color: #FFFFFF;
    }
  }

  .entities-description-items {
    width: 980px;
    padding-left: 15px;
    padding-right: 15px;
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    .entities-description-title {
      span {
        width: 720px;
      }
    }

    .entities-description-items {
      width: 720px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    margin-top: 1.4rem;

    .entities-description-title {
      span {
        width: 100%;
        text-align: center;
      }
    }

    .entities-description-items {
      width: 100%;
    }
  }
`;

function EntitiesDescriptionSection({ products, entitiesDescriptionRef }) {
  const items = useMemo(() => {
    return products.map(({ code, customerName, detailedDescription }) => {
      return {
        id: code,
        title: customerName,
        collapsibleContent: detailedDescription,
      };
    });
  }, [products]);

  return (
    <StyledEntitiesDescriptionSection ref={entitiesDescriptionRef}>
      <div className="entities-description-title">
        <span>Not Sure Which Business Entity to Select?</span>
      </div>
      <div className="entities-description-items">
        <CollapsibleList items={items} />
      </div>
    </StyledEntitiesDescriptionSection>
  );
}

export default EntitiesDescriptionSection;
