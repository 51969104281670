import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

import { hexToRGBA } from "../../../utils/theme";

const { publicRuntimeConfig } = getConfig();

const StyledApplicationStepsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  padding-top: 60px;

  background: #F2F0F0;

  .background-logo {
    position: absolute;

    top: 60px;
    left: 0;
    bottom: 0;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 20px;
    margin-bottom: 46px;

    z-index: 1;

    span {
      display: block;
      color: #C61F0C;
      text-shadow: 1px 1px 0px #FFFFFF;
      text-transform: capitalize;
    }

    span:nth-child(1) {
      margin-bottom: 8px;
      font-family: 'MerriweatherBold';
      font-size: 42px;
      line-height: 53px;
      text-align: center;
    }

    span:nth-child(2) {
      font-family: 'MerriweatherLight';
      font-size: 24px;
      line-height: 30px;
      text-align: center;
    }
  }

  .application-steps {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-bottom: 60px;

    z-index: 1;

    span {
      display: block;
      margin-bottom: 16px;
      font-family: 'MerriweatherLight';
      font-size: 18px;
      line-height: 28px;
      text-align: center;
      color: #1B3858;
    }
  }

  .application-options {
    display: flex;
    justify-content: center;

    width: 100%;

    > span {
      display: flex;
      flex-direction: column;
      align-items: center;

      width: 20%;

      span {
        width: 100%;

        margin-top: 24px;
        margin-bottom: 24px;

        font-family: 'MerriweatherLight';
        font-size: 18px;
        line-height: 28px;
        text-align: center;
        color: #1B3858;
      }

      &:nth-child(1) {
        border-right: 1px solid ${hexToRGBA('#000000', 0.1)};
      }

      &:nth-child(2) {
        border-left: 1px solid ${hexToRGBA('#FFFFFF', 0.75)};
        border-right: 1px solid ${hexToRGBA('#000000', 0.1)};
      }

      &:nth-child(3) {
        border-left: 1px solid ${hexToRGBA('#FFFFFF', 0.75)};
      }
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 24px;

    .background-logo {
      position: inherit;
      
      top: 0;
      padding-right: 16px;

      img {
        width: 100%;
      }
    }

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;

      padding-left: 36px;
      padding-right: 36px;
      margin-top: 16px;
      margin-bottom: 36px;

      span:nth-child(1) {
        margin-bottom: 20px;
        font-size: 32px;
        line-height: 40px;
      }

      span:nth-child(2) {
        font-size: 22px;
        line-height: 28px;
      }
    }

    .application-steps {
      padding-left: 28px;
      padding-right: 28px;
      margin-bottom: 0;

      span {
        margin-bottom: 8px;
        font-size: 16px;
        line-height: 28px;
      }
    }

    .application-options {
      display: flex;
      flex-direction: column;
      align-items: center;

      > span {
        width: 100%;
        padding-top: 40px;

        &:nth-child(1) {
          border-right: none;
          border-bottom: 1px solid ${hexToRGBA('#000000', 0.1)};
        }

        &:nth-child(2) {
          border-left: none;
          border-right: none;
          border-top: 1px solid ${hexToRGBA('#FFFFFF', 0.75)};
          border-bottom: 1px solid ${hexToRGBA('#000000', 0.1)};
        }

        &:nth-child(3) {
          border-left: none;
          border-top: 1px solid ${hexToRGBA('#FFFFFF', 0.75)};
        }
      }
    }
  }
`;

function ApplicationStepsSection() {
 return (
   <StyledApplicationStepsSection>
     <div className="title">
      <span>
        Start the EIN Filing Process
      </span>
      <span>
        Here’s How It Works:
      </span>
     </div>
     <div className="application-steps">
      <span>
        1. Complete and submit your EIN Application
      </span>
      <span>
        2. IRS Compliance Review
      </span>
      <span>
        3. Receive your EIN paperwork in your email inbox as a PDF
      </span>
     </div>
     <div className="application-options">
      <span>
        <img src={`${publicRuntimeConfig.basePath}/icons/V2/business.svg`} alt="" />
        <span>Form a Business</span>
      </span>
      <span>
        <img src={`${publicRuntimeConfig.basePath}/icons/V2/trust.svg`} alt="" />
        <span>Create a Trust</span>
      </span>
      <span>
        <img src={`${publicRuntimeConfig.basePath}/icons/V2/create.svg`} alt="" />
        <span>Add New Employees</span>
      </span>
     </div>
     <div className="background-logo">
      <img src={`${publicRuntimeConfig.basePath}/images/V2/flag.png`} alt="" />
     </div>
   </StyledApplicationStepsSection>
 );
}

export default ApplicationStepsSection;
