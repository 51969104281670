import React from "react";
import styled from "styled-components";

const StyledApplicationProcessSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 70px;
  padding-bottom: 80px;

  .title {
    margin-bottom: 32px;
    text-align: center;

    span {
      font-family: 'MerriweatherBold';
      font-size: 42px;
      line-height: 53px;
      text-transform: capitalize;
      color: #1B3858;
      text-shadow: 1px 1px 0px #FFFFFF;
    }
  }

  .content-box {
    width: 50%;

    span {
      display: block;
      margin-bottom: 16px;

      font-family: 'LatoRegular';
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      color: #1B3858;
    }
  }

  @media ${({ theme }) => theme.devices.laptopL} {
    .content-box {
      width: 60%;
    }
  }

  @media ${({ theme }) => theme.devices.laptopM} {
    .content-box {
      width: 70%;
    }
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    .content-box {
      width: 80%;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 40px;
    padding-bottom: 24px;
    padding-left: 20px;
    padding-right: 20px;

    .title {
      margin-bottom: 28px;

      span {
        font-size: 32px;
        line-height: 40px;
      }
    }

    .content-box {
      width: 100%;
    }
  }
`;

function ApplicationProcessSection() {
  return (
    <StyledApplicationProcessSection>
      <div className="title">
        <span>
          EIN Application Process Explained
        </span>
      </div>
      <div className="content-box">
        <span>
          The process of applying for an EIN can be confusing and strenuous for small business owners and entrepreneurs. 
          Whether you’re a business owner, entrepreneur, executor, administrator, or representative of an estate, 
          we’ll work with the IRS on your behalf as your third party designee.
        </span>
        <span>
          Our team of accountants, tax professionals, and small business experts are standing by to process your application. 
          We’ll review all the information you provide and advise on anything you may need to add or revise in order to get your application accepted. 
          Once your application has been thoroughly reviewed by our team, we’ll submit it electronically to obtain your EIN. 
          If any additional documents are needed, our team will work with the IRS directly to save you time.
        </span>
        <span>
          At any point during the process, you can contact our experts to help. 
          Once your application is submitted, you’ll receive an email confirmation, as well as an expected delivery date. 
          Once your application has been approved, you’ll receive your EIN electronically. Most EIN applications are approved. 
          If, for any reason, your application is rejected, we will contact you directly for next steps.
        </span>
      </div>
    </StyledApplicationProcessSection>
  );
}

export default ApplicationProcessSection;
