import React from "react";
import styled from "styled-components";

const StyledCollapsibleItem = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 24px;
  padding-right: 24px;

  border-bottom: 2px solid #E6F4FE;

  .item-title  {
    display: flex;
    justify-content: space-between;

    font-family: 'MerriweatherLight';
    font-size: 18px;
    line-height: 28px;
    text-decoration: none;
    color: #1B3858;

    &:not(.collapsed) {
      color: #C61F0C;
    }

    &:after {
      content: "↓";
      color: #078FF1;
    }

    &:not(.collapsed):after {
      content: "↑";
      color: #C61F0C;
    }
  }

  .item-collapsible {
    margin-top: 18px;

    span {
      font-family: 'LatoRegular';
      font-size: 14px;
      line-height: 24px;
      color: #1B3858;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-left: 0;
    padding-right: 0;

    .item-title {
      justify-content: flex-start;

      font-size: 16px;

      &:after {
        display: none;
      }

      &:before {
        content: "↓";
        margin-right: 10px;
        color: #078FF1;
      }

      &:not(.collapsed):before {
        content: "↑";
        color: #C61F0C;
      }
    }

    .item-collapsible {
      span {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
`;

function CollapsibleItem({ id, title, collapsibleText }) {
  return (
    <StyledCollapsibleItem>
      <a
        data-bs-toggle="collapse"
        role="button"
        href={`#collapse-${id}`}
        aria-expanded="false"
        aria-controls={`collapse-${id}`}
        className="item-title collapsed"
      >
        {title}
      </a>
      <div id={`collapse-${id}`} className="item-collapsible collapse">
        <span>{collapsibleText}</span>
      </div>
    </StyledCollapsibleItem>
  );
}

export default CollapsibleItem;
