import React, { useMemo } from "react";
import styled, { css } from "styled-components";
import { isMobile } from "react-device-detect";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const StyledProductCard = styled.div`
  display: flex;

  min-height: 84px;
  height: 100%;
  width: 100%;

  background: #FFFFFF;
  box-shadow: 0px 5px 15px rgba(92, 191, 219, 0.3);

  cursor: pointer;

  ${({ expanded }) => !expanded && css`
    flex-direction: column;
    align-items: center;
    padding-top: 40px;

    .product-card-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;
      
      width: 100%;

      .product-card-title {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        
        padding-left: 28px;
        padding-right: 28px;

        span {
          font-family: 'LatoRegular';
          font-size: 14px;
          line-height: 24px;
          color: #1B3858;
          text-align: center;
        }
      }

      .product-card-description {
        display: none;
      }
    }
  `};

  ${({ expanded }) => expanded && css`
    justify-content: flex-start;
    align-items: center;

    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 40px;
    padding-right: 30px;

    .product-card-icon {
      margin-right: 12%;
    }

    .product-card-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      height: 100%;
      
      .product-card-title {
        display: flex;
        justify-content: flex-start;
        padding-top: 16px;
        margin-bottom: 8px;

        span {
          font-family: 'MerriweatherLight';
          font-size: 18px;
          line-height: 28px;
          color: #C61F0C;
          text-align: left;
        }
      }

      .product-card-description {
        display: block;
        font-family: 'LatoRegular';
        font-size: 12px;
        line-height: 22px;
        color: #1B3858;
      }
    }
  `};

  @media ${({ theme }) => theme.devices.tablet} {
    ${({ expanded }) => !expanded && css`
      flex-direction: row;
      justify-content: flex-start;

      padding: 20px;

      .product-card-icon {
        margin-right: 20px;
      }

      .product-card-content {
        .product-card-title {
          justify-content: flex-start;
          padding: 0;

          span {
            text-align: left;
          }
        }
      }
    `};

    ${({ expanded }) => expanded && css`
      flex-direction: column;
      padding: 20px;

      .product-card-icon {
        margin-right: 0;
        margin-bottom: 16px;
      }

      .product-card-content {
        align-self: center;

        .product-card-title {
          justify-content: center;
          padding: 0;

          span {
            text-align: center;
          }
        }

        .product-card-description {
          text-align: center;
        }
      }
    `};
  }
`;

function ProductCard({ expanded, id, iconSrc, title, description }) {
  const overlayTriggerProps = useMemo(() => {
    const props = {
      key: id,
      placement: isMobile ? "top" : "right",
      delay: { show: 0, hide: 0 },
      trigger: ["hover", "focus"],
      overlay: (
        <Tooltip id={`tooltip-${id}`}>
          {description}
        </Tooltip>
      ),
    };

    if (expanded) {
      props.show = false;
    }

    return props;
  }, [id, description, expanded]);

  return (
    <OverlayTrigger {...overlayTriggerProps}>
      <StyledProductCard tabIndex={0} expanded={Boolean(expanded)}>
        <div className="product-card-icon">
          <img
            src={iconSrc}
            alt=""
            layout="fill"
          />
        </div>
        <div className="product-card-content">
          <div className="product-card-title">
            <span>{title}</span>
          </div>
          <div className="product-card-description">
            <span>{description}</span>
          </div>
        </div>
      </StyledProductCard>
    </OverlayTrigger>
  );
}

export default ProductCard;
