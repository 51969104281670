import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Link from "next/link";
import { config } from "../../../../../../_config";
import LinkButton from "../../../V2-reflection-3/styled/LinkButton";
import axios from "axios";

const { email } = config;
const StyledEntitiesFAQSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  font-family: RobotoRegular;
  color: #39393d;
  font-size: 16px;
  padding-left: 15px;
  padding-right: 15px;
  
  .getStartedRow {
    margin: 40px;
    text-align: center;
  }
  @media (min-width: 1024px) {
    .getStartedRow {
      margin: 60px 0 50px;
    }
  }
  .entities-faq-title {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;

    span {
      font-family: 'RobotoBold';
      font-size: 38px;
      line-height: calc(1.42857rem * (28 / 16));
      color: #39393d;
    }
  }

  .entities-faq-container {
    max-width: 980px;
    .indexFAQ__question {
      position: relative;
      margin: 30px 0 8px;
      font-family: RobotoBold;
      font-size: 24px;
    }

    .indexFAQ__answer {
      font-size: 16px;
      margin: 0 0 10px;
    }
    .indexFAQ__answer ul {
      margin-top: 8px;
      list-style: disc;
      font-size: 16px;
      padding-inline-start: 40px;
    }
    .indexFAQ__answer li {
      font-size: 16px;
    }
    @media (min-width: 500px) {
      .indexFAQ__question:before {
        position: absolute;
        top: 10px;
        left: -50px;
        display: block;
        width: 30px;
        height: 2px;
        background-color: #8f8f8f;
        content: "";
      }
    }
  }
  @media (min-width: 1024px) {
    .entities-faq-container {
      margin-left: 13%;
    }
  }
  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1024px) {
    width: 990px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  @media (min-width: 1280px) {
    padding-left: 40px;
    padding-right: 40px;
    width: 1170px;
  }
  @media (min-width: 1600px) {
    padding-left: 115px;
    padding-right: 115px;
    width: 1440px;
  }
`;

function EntitiesFAQSection() {
  const [price, setPrice] = useState("");

  useEffect(() => {
    axios
      .get(process.env.NEXT_PUBLIC_REACT_APP_API + "/category/EIN/products/prices?productCodes=EIN_SoleProp",
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .then((response) => {
        const price = response.data.find(({ option }) => option === "STANDARD")?.price;
        setPrice(price);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <StyledEntitiesFAQSection>
      <div className="entities-faq-title">
        <span>
          Frequently Asked Questions
        </span>
      </div>
      <div className="entities-faq-container">
        <ul>
          <li>
          <div className="indexFAQ__question">
              Is an EIN the same thing as a Tax ID?
          </div>
          <div className="indexFAQ__answer">
              Yes. An Employer Identification Number or EIN is the same as a Tax ID.
          </div>
        </li>
        <li>
          <div className="indexFAQ__question">
              Do I need an EIN Number or Tax ID?
          </div>
          <div className="indexFAQ__answer">
            You need an EIN number if you are:
              <ul>
                <li>Starting a new business</li>
                <li>Starting payroll for employees</li>
                <li>Filing business tax returns</li>
                <li>Setting up a business SEP IRA, 401k, Solo 401k, or Keogh Plan</li>
                <li>Handling as Estate</li>
                <li>Setting up a Trust</li>
                <li>Setting up a Non-Profit</li>
                <li>Starting a Partnership</li>
              </ul>
          </div>
        </li>
          <li>
            <div className="indexFAQ__question">
              How long will it take to get my new EIN number?
          </div>
            <div className="indexFAQ__answer">
              We offer a Rush Delivery in which one of our agents will obtain your EIN from the IRS on your behalf in as little as 60 minutes during business hours. Our other option is Standard Delivery which takes 1-2 business days.
          </div>
        </li>
          <li>
            <div className="indexFAQ__question">
              How do I contact Support or Make Changes to my Application?
          </div>
            <div className="indexFAQ__answer">
              Please visit <Link href="/contactus"><a>Contact Us</a></Link> or E-mail us: <a href={`mailto:${email}?subject=Mail from Site`}>
                    {email}
              </a>
          </div>
        </li>
          <li>
            <div className="indexFAQ__question">
              What Does it Cost to File for an EIN Number?
          </div>
            <div className="indexFAQ__answer">
              Standard Pricing is $<span>{price}</span>. Same day Delivery is $30.00 extra. 60 Minute RUSH Delivery is $50.00 extra.
          </div>
        </li>
        </ul>
      </div>
      <div className="getStartedRow">
      <Link href="#getStarted">
        <LinkButton>
          Get Started
        </LinkButton>
      </Link>
      </div>
    </StyledEntitiesFAQSection>
  );
}

export default EntitiesFAQSection;
