import React from "react";
import styled from "styled-components";
import getConfig from "next/config";
import { useRouter } from "next/router";

const { publicRuntimeConfig } = getConfig();

const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 16px;
  padding-right: 16px;

  .section-title {
    padding-bottom: 60px;
    font-family: Prata;
    font-weight: 400;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    color: #000000;
    text-decoration: underline;
  }
  .section-content-container {
    padding-top: 40px;
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-direction: column;
    align-items: center;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 32px;
    padding-bottom: 32px;

    .section-title {
      padding-bottom: 8px;
      font-size: 38px;
      line-height: 42px;
    }

    .section-content-container {
      flex-direction: column;
      gap: 32px;

      .section-card-container {
        min-height: unset;

        .section-card-image {
          margin-bottom: 16px;
        }

        .section-card-title {
          margin-bottom: 8px;
          font-size: 24px;
          line-height: 32px;
        }

        .section-card-text {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
`;
const StyledButton = styled.div`
  background: #085AAB;
  box-shadow: 0px 4px 4px 0px #00000040;
  color: #FFFFFF;
  font-family: Prata;
  padding-top: 12px;
  padding-bottom: 8px;
  font-size: 21px;
  font-weight: 400;
  line-height: 28.66px;
  width: 80%;
  border-radius: 5px;
  cursor: pointer;
` ;
const StyledCard = styled.div`
  border: 1px solid #00000080;
  box-shadow: 0px 4px 4px 0px #00000040;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Prata;
  text-align: center;
  padding: 120px;
  padding-top: 20px;
  padding-bottom: 20px;
  flex: 1 1 0;
  color: #000000;
  justify-content: space-between;
  max-width: 745px;
  .title {
    font-weight: 400;
    font-size: 32px;
    line-height: 42px;
    text-decoration: underline;
  }
  .details {
    margin-top: 16px;
    margin-bottom: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  .containerButton {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @media ${({ theme }) => theme.devices.tablet} {
    padding: 16px;
  }
`;
const OurServicesSection = ({onGoToContactUs}) => {
  const router = useRouter();
  const handleBasicClick = () => {
    router.push("https://turbotax.intuit.com/personal-taxes/online/live/full-service/business-taxes/");
  };
  const items = [
    {
      title: 'Tax Preparation & Filing',
      details: 'Expertly navigate tax season with our precise and personalized tax filing services. We handle everything from individual returns to complex corporate taxes, ensuring compliance and maximizing your savings',
      buttonText: 'START FILING NOW',
      handle: handleBasicClick,
    },
    {
      title: 'Accounting',
      details: 'Our trained accountants keep your business finances in check. We manage daily operations and strategic financial planning, ensuring your records are always accurate and up-to-date',
      buttonText: 'CHAT WITH US',
      handle: onGoToContactUs,
    },
    {
      title: 'Bookkeeping',
      details: 'Ensure accuracy in your financial records with our professional bookkeeping services. From payroll to invoices, we maintain impeccable books so you can focus on your business goals',
      buttonText: 'CHAT WITH US',
      handle: onGoToContactUs,
    }
  ];

  return (
    <StyleOuterContainer>
      <StyledInnerContainer>
        <div className="section-title">What We Offer</div>
        <div className="section-content-container">
          {items?.map(({ title, details, buttonText, handle }, j) => (
            <StyledCard key={'card' + j}>
              <div className="title">{title}</div>
              <div className="details">{details}</div>
              <div className="containerButton">
                <StyledButton onClick={handle}>{buttonText}</StyledButton>
              </div>
            </StyledCard>))}
        </div>
      </StyledInnerContainer>
    </StyleOuterContainer>
  );
};

export default OurServicesSection;
