import React, { useEffect } from "react";
import styled from "styled-components";
import getConfig from "next/config";
import Link from "next/link";

import { hexToRGBA } from "../../../utils/theme";
import ProductCard from "../../ProductCard";

const { publicRuntimeConfig } = getConfig();

const StyledProductsSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 40px;
  padding-bottom: 90px;

  .product-cards {
    display: flex;
    flex-wrap: wrap;

    width: 80%;

    padding: 24px;
    margin-bottom: 26px;

    background: ${hexToRGBA('#078FF1', 0.1)};

    .product-card-container {
      min-height: 170px;
      width: calc(20% - 16px);
      margin: 8px;
    }
  }

  .bottom-content {
    width: 80%;
    text-align: center;

    a {
      text-decoration: underline;
      color: #078FF1;
    }
  }

  @media ${({ theme }) => theme.devices.laptopM} {
    .bottom-content,
    .product-cards {
      width: 90%;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 20px;
    padding-bottom: 20px;

    .product-cards {
      flex-direction: column;
      padding: 18px;

      .product-card-container {
        min-height: auto;
        width: calc(100% - 12px);
        margin: 6px;
      }
    }
  }
`;

function ProductsSection({ products }) {

  useEffect(() => {
    if (localStorage.getItem("crmSessionId")) {
      localStorage.removeItem("crmSessionId");
    }
  }, []);

  return (
    <StyledProductsSection>
      <div className="product-cards">
        {products.map(({ code, path, iconSrc, customerName, description }) => (
          <Link key={code} href={`/products/${path}`} passHref>
            <div className="product-card-container">
              <ProductCard
                id={code}
                iconSrc={`${publicRuntimeConfig.basePath}${iconSrc}`}
                title={customerName}
                description={description}
              />
            </div>
          </Link>
        ))}
      </div>
      <div className="bottom-content">
        <Link href={"/entities"} passHref>
          <a>
            Learn more about legal entities
          </a>
        </Link>
        <span>
          &nbsp;if you are not sure which type of legal structure you should choose
        </span>
      </div>
    </StyledProductsSection>
  );
}

export default ProductsSection;
