import React from "react";
import styled from "styled-components";
import getConfig from "next/config";
import Link from "next/link";
import { Button } from "react-bootstrap";

const { publicRuntimeConfig } = getConfig();

const StyledOurSpecialistsSection = styled.div`
    padding-left: 15px;
    padding-right: 15px;
    width: 100%;
  background: #FFFFFF;
  .ourSpecialists {
    position: relative;
    display: flex;
    flex-flow: column nowrap;
  }
  .ourSpecialists__details {
    order: 1;
    color: #39393d;
    font-family: Myriad Pro, RobotoRegular, sans-serif;
    text-align: center;
  }
  .ourSpecialists__line {
    font-size: 34px;
    line-height: 1.2;
  }
  .ourSpecialists__line--hl {
    font-size: 31px;
    font-weight: 700;
    font-family: RobotoBold;
  }
  .ourSpecialists__contact {
    margin: 20px 0 30px;
    font-size: 18px;
    line-height: 1.2;
  }
  img {
    max-width: 100%;
    height: auto;
  }
  .ourSpecialists__img {
    order: 2;
    width: 238px;
    margin: 0 auto;
  }
  .link {
    color: #055393;
    font-family: Myriad Pro, RobotoRegular, sans-serif;
  }

  .button.get-started {
    margin: 0;
    padding: 15px 32px;
    font-size: 21px;
    font-family: Myriad Pro, Roboto, sans-serif;
    font-weight: 700;
    line-height: 1;
    background-color: #ff9331;
    border-radius: 6px;
  }

  .button {
    position: relative;
    display: inline-block;
    margin: 0 0 1.25rem;
    padding: 1.5rem 2rem;
    color: #fff;
    font-weight: 400;
    font-size: 17px;
    font-family: Roboto, sans-serif;
    line-height: normal;
    text-align: center;
    text-decoration: none;
    background-color: #008cba;
    border: 0 solid #007095;
    border-radius: 0;
    cursor: pointer;
    transition: background-color .3s ease-out;
    -webkit-appearance: none;
  }
  .ourSpecialists__btn {
    position: absolute !important;
    left: 0;
    bottom: 4%;
    width: 100%;
    color: #055393 !important;
    background-color: #fff !important;
  }
  
  @media (min-width: 1024px) {
    .ourSpecialists__btn {
      position: static !important;
      width: auto;
      color: #fff !important;
      background-color: #ff9331 !important;
    }
    .ourSpecialists__img {
      order: 1;
      width: 338px;
      margin-right: 100px;
      margin-left: 20px;
    }
    .ourSpecialists {
      flex-flow: row nowrap;
    }
    .ourSpecialists__line {
      font-size: 50px;
    }
    .ourSpecialists__line--hl {
      font-size: 50px;
    }
    .ourSpecialists__details {
      order: 2;
      margin: 60px 0 30px;
      text-align: left;
    }
    @media (min-width: 1024px) {
      .ourSpecialists__contact {
        font-size: 21px;
      }
    }
  }
  @media (min-width: 768px) {
      width: 750px;
  }
  @media (min-width: 992px) {
      width: 970px;
  }

  @media (min-width: 1024px) {
      width: 990px;
  }
  @media (min-width: 1200px) {
      width: 1170px;
  }
  @media (min-width: 1280px) {
      padding-left: 40px;
      padding-right: 40px;
      width: 1170px;
  }
  @media (min-width: 1600px) {
      width: 1440px;
      padding-left: 115px;
      padding-right: 115px;
  }
`;

function OurSpecialistsSection() {
  return (
    <StyledOurSpecialistsSection>
      <div className="ourSpecialists">
        <img
          src={`${publicRuntimeConfig.basePath}/images/V2/V2-reflection-3/specialist.png`}
          className="ourSpecialists__img"
          alt="specialist"
        />
        <div className="ourSpecialists__details">
          <div className="ourSpecialists__line">Trained EIN Specialists</div>
          <div className="ourSpecialists__line">Review your application</div>
          <div className="ourSpecialists__line ourSpecialists__line--hl">Guaranteed done right.</div>
          <div className="ourSpecialists__contact">Our trained EIN specialists are available Monday through <br />Friday
            from 7AM - 10PM EST. <Link href="/contactus"><a className="link">Click here to email them</a></Link>.</div>
          <Link href="#getStarted">
            <Button className="button get-started ourSpecialists__btn">
              Get Started
            </Button>
          </Link>
        </div>
      </div>
    </StyledOurSpecialistsSection>
  );
}

export default OurSpecialistsSection;
 
