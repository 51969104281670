import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .background-image {
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    .background-image {
      top: -1px;
      bottom: -1px;
    }
  }
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  min-height: 888px;

  .section-title {
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: "Prata";
    font-weight: 400;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    color: #ffffff;
  }

  .section-text {
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: "Prata";
    font-weight: 400;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    color: #ffffff;
  }
  .section-deadline {
    padding-top: 4px;
    padding-bottom: 4px;
    font-family: "Prata";
    font-weight: 400;
    font-size: 28px;
    line-height: 55px;
    text-align: center;
    color: #F93C3C;
  }
  a {
    display: block;
    margin-top: 60px;
    margin-bottom: 8px;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 120px;
    padding-right: 120px;
    border-radius: 10px;
    background: #ffffff;
    font-family: "Prata";
    font-weight: 400;
    font-size: 48px;
    line-height: 32px;
    text-align: center;
    color: #000000;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    min-height: unset;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;

    .section-title {
      font-size: 18px;
      line-height: 20px;
    }

    .section-text {
      font-size: 18px;
      line-height: 20px;
    }
    .section-deadline {
      font-size: 16px;
    }
    a {
      display: block;
      margin-top: 16px;
      margin-bottom: 0px;
      padding-left: 16px;
      padding-right: 16px;
      padding-top: 8px;
      padding-bottom: 8px;
      font-size: 20px;
      line-height: 28px;
    }
  }
`;

const StartFilingSection = () => {
  return (
    <StyleOuterContainer>
      <StyledInnerContainer>
        <div className="section-title">2023 Tax Deadline Is Coming Up</div>
        <div className="section-text">
          Avoid Late Fees and Start Filing
        </div>
        <a href="https://turbotax.intuit.com/personal-taxes/online/live/full-service/business-taxes/" target="_blank">
          Start Filing Now
        </a>
        <div className="section-deadline">
          DEADLINE: OCTOBER 15th, 2024
        </div>
      </StyledInnerContainer>
      <div className="background-image">
        <picture>
          <source srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-5/background.png`} type="image/png" />
          <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-5/background.png`} alt="Background Image" />
        </picture>
      </div>
    </StyleOuterContainer>
  );
};

export default StartFilingSection;
