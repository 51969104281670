import React from "react";
import styled from "styled-components";
import { config as publicRuntimeConfig } from "../../../../../../_config";

const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 16px;
  padding-right: 16px;
  

  .section-content-container {
    display: flex;
    justify-content: flex-start;
    width: 80%;
    padding: 50px;
    background: linear-gradient(0deg, #094B88, #094B88);
    border-radius: 20px;
    gap: 100px;
    .divider {
      width: 1px;
      border: 1px solid #ffffff;
    }
    .section-content-column {
      width: 70%;
      color: #ffffff;
      .section-content-column-title {
        margin-bottom: 16px;
        font-family: NoticiaText;
        font-weight: 700;
        font-size: 28px;
        line-height: 22px;
      }
      .list-header {
        font-family: NoticiaText;
        font-size: 18px;
        font-weight: 700;
        line-height: 24.57px;
      }
      ul {
        list-style: disc;
        margin-left: 18px;
        font-family: NoticiaText;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
      }
      .section-content-column-text {
        margin-bottom: 4px;
        font-family: NoticiaText;
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        text-align: right;
        padding-right: 40px;
      }

      .section-content-column-subtext {
        margin-bottom: 4px;
        font-family: NoticiaText;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
        text-align: right;
        padding-right: 40px;
      }

      .section-content-column-message {
        padding-top: 32px;
        font-family: NoticiaText;
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #07437a;
      }
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 32px;
    padding-bottom: 32px;

    .section-title {
      margin-bottom: 24px;
      font-size: 38px;
      line-height: 42px;
    }

    .section-content-container {
      flex-direction: column;
      gap: 32px;
      width: 100%;
      padding: 16px;
      img {
        width: 100%;
      }
      .divider {
        height: 1px;
        width: 100%;
        border: 1px solid #ffffff;
      }
      .section-content-column {
        width: 100%;

        &:last-child {
          padding-left: unset;
        }

        .section-content-column-title {
          margin-bottom: 16px;
          font-size: 20px;
          line-height: 20px;
        }
      }
    }
  }
`;

const StyleTopText = styled.div`
  border-width: 2px;
  border-style: solid;
  border-color: #333232;
  font-family: NoticiaText;
  font-size: 14px;
  font-weight: 700;
  line-height: 19.11px;
  padding-left: 80px;
  padding-right: 80px;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  border-bottom: none;
  @media ${({ theme }) => theme.devices.tablet} {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 12px;
  }
` ;
const StyledButton = styled.div`
  border: 1px solid #000000;
  background: #ffffff;
  color: #333232;
  font-family: Aeonik;
  font-size: 18px;
  font-weight: 700;
  line-height: 28.66px;
  width: 80%;
  border-radius: 20px;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  margin-top: 24px;
  @media ${({ theme }) => theme.devices.tablet} {
    width: 100%;
  }
` ;
const StartFilingSection = () => {
  return (
    <StyleOuterContainer>
      <StyledInnerContainer>
        <StyleTopText>Extension Deadline: October 15th, 2024</StyleTopText>
        <div className="section-content-container">
          <div className="section-content-column">
            <div className="section-content-column-title">File taxes the way you want</div>
            <div className="list-header">We file taxes for</div>
            <ul><li>LLCs</li>
              <li>Sole Proprietors</li>
                <li>Corporations</li>
                  <li>Non Profits</li>
                    <li>Church Organizations</li>
            </ul>
            <a href="https://turbotax.intuit.com/personal-taxes/online/live/full-service/business-taxes/" target="_blank">
            <StyledButton>Start E-Filing Your 2023 Return</StyledButton>
            </a>
          </div>

          <div className="divider" />
          <picture>
            <source
              srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-2/start.png`}
              type="image/png"
            />
            <img src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-2/start.png`} alt="" />
          </picture>
        </div>
      </StyledInnerContainer>
    </StyleOuterContainer>
  );
};

export default StartFilingSection;
