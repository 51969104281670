import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1444px;
  border-top: 1px solid #000000;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 16px;
  padding-right: 16px;

  .section-title {
    margin-bottom: 40px;
    font-family: Platypi;
    font-weight: 700;
    font-size: 38px;
    line-height: 55px;
    text-align: center;
    color: #07437a;
    text-decoration: underline;
  }

  .section-content-container {
    display: flex;
    gap: 16px;
    justify-content: space-between;
    width: 80%;
    align-items: stretch;
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 32px;
    padding-bottom: 32px;

    .section-title {
      margin-bottom: 24px;
      font-size: 38px;
      line-height: 42px;
    }

    .section-content-container {
      flex-direction: column;
      gap: 32px;

      .section-card-container {
        min-height: unset;

        .section-card-image {
          margin-bottom: 16px;
        }

        .section-card-title {
          margin-bottom: 8px;
          font-size: 24px;
          line-height: 32px;
        }

        .section-card-text {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
`;
const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Platypi;
  text-align: center;
  padding: 8px;
  flex: 1 1 0;
  .image {
    height: 93px;
    align-content: center;
  }
  .title {
    margin-top: 16px;
    font-weight: 700;
    font-size: 24px;
    color: #07437A;
    line-height: 32px;
  }
  .details {
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }
`;
const OurGuaranteesSection = () => {
  const items = [
    {
      title: 'Largest Refund Promise',
      image: `${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/guarantees1.png`,
      details: 'Secure the largest refund possible. If another service offers you a bigger refund, we’ll cover your tax preparation fees.'
    },
    {
      title: 'Clear Cost Commitment',
      image: `${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/guarantees2.png`,
      details: 'Know your costs upfront. Our pricing guarantee ensures there are no surprises—you\'ll know the full cost of tax preparation before we start.',
    },
    {
      title: 'Precision Tax Handling',
      image: `${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/guarantees3.png`,
      details: 'Experience you can trust. Our team averages over 10 years of experience, ensuring precise handling of all tax situations, no matter how complex.',
    }
  ];
  return (
    <StyleOuterContainer>
      <StyledInnerContainer>
        <div className="section-content-container">
          {items?.map(({ title, details, image }, j) => (
          <StyledCard key={'card' + j}>
            <div className="image">
            <picture>
              <source
                srcSet={image}
                type="image/png"
              />
              <img src={image} alt="" />
            </picture>
            </div>
            <div className="title">{title}</div>
            <div className="details">{details}</div></StyledCard>))}
        </div>
      </StyledInnerContainer>
    </StyleOuterContainer>
  );
};

export default OurGuaranteesSection;
