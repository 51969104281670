import React from "react";
import styled from "styled-components";

import CollapsibleItem from "./CollapsibleItem";

export const StyledCollapsibleList = styled.div`
  padding: 24px;
  background: #FFFFFF;
  box-shadow: 0px 5px 15px rgba(92, 191, 219, 0.3);
`;

function CollapsibleList({ items = [] }) {
  return (
    <StyledCollapsibleList>
      {items.map(({ id, ...restProps }) => (
        <CollapsibleItem key={id} id={id} {...restProps} />
      ))}
    </StyledCollapsibleList>
  );
}

export default CollapsibleList;
