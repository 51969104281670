import React from "react";
import styled from "styled-components";
import getConfig from "next/config";
import { useRouter } from "next/router";

const { publicRuntimeConfig } = getConfig();

const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  padding-top: 20px;
  padding-bottom: 80px;
  padding-left: 16px;
  padding-right: 16px;

  .section-title {
    margin-bottom: 8px;
    font-family: Platypi;
    font-weight: 700;
    font-size: 38px;
    line-height: 55px;
    text-align: center;
    color: #07437a;
    text-decoration: underline;
  }
  .section-subtitle {
    font-size: 24px;
    font-family: Platypi;
    margin-bottom: 40px;
    font-weight: 400;
    text-align: center;
    color: #4B4B4B;
  }
  .section-content-container {
    border-top: 1px solid #000000;
    padding-top: 40px;
    display: flex;
    justify-content: center;
    gap: 80px;

    .section-card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 442px;

      .section-card-image {
        margin-bottom: 24px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .section-card-title {
        margin-bottom: 16px;
        font-family: Platypi;
        font-weight: 700;
        font-size: 32px;
        line-height: 32px;
        text-align: center;
        color: #07437a;
      }

      .section-card-text {
        font-family: Platypi;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: #07437a;
      }
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 32px;
    padding-bottom: 32px;

    .section-title {
      margin-bottom: 24px;
      font-size: 38px;
      line-height: 42px;
    }

    .section-content-container {
      flex-direction: column;
      gap: 32px;

      .section-card-container {
        min-height: unset;

        .section-card-image {
          margin-bottom: 16px;
        }

        .section-card-title {
          margin-bottom: 8px;
          font-size: 24px;
          line-height: 32px;
        }

        .section-card-text {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
`;
const StyledCard = styled.div`
  background: linear-gradient(360deg, #094B88 99.98%, #1084EE 99.99%, #094B88 100%);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Platypi;
  text-align: center;
  padding: 20px;
  padding-bottom: 40px;
  flex: 1 1 0;
  color: #ffffff;
  justify-content: flex-start;
  .title {
    margin-top: 16px;
    font-weight: 700;
    font-size: 32px;
    line-height: 32px;
  }
  .details {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: left;
    max-width: 90%;
  }
  .divider {
    height: 1px;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 5px;
    border: 1px solid #ffffff;
  }
`;
const OurServicesSection = () => {
  const items = [
    {
      title: 'Tax Filing',
      image: `${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/services1.png`,
      details: 'Get Your Taxes Filed with No Errors',
    },
    {
      title: 'Accounting',
      image: `${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/services2.png`,
      details: "Trained Accountants to Help Keep Your Business Finances in Check",
    },
    {
      title: 'Bookkeeping',
      image: `${publicRuntimeConfig.basePath}/images/V2/V2-landing-4/services3.png`,
      details: 'Keep your books done right',
    }
  ];

  return (
    <StyleOuterContainer>
      <StyledInnerContainer>
        <div className="section-title">Our Services</div>
        <div className="section-subtitle">We Help Keep Your Finances In Check</div>
        <div className="section-content-container">
          {items?.map(({ title, details, image }, j) => (
            <StyledCard key={'card' + j}>
              <picture>
                <source
                  srcSet={image}
                  type="image/png"
                />
                <img src={image} alt="" />
              </picture>
                <div className="divider" />
              <div className="title">{title}</div>
              <div className="details">{details}</div>
            </StyledCard>))}
        </div>
      </StyledInnerContainer>
    </StyleOuterContainer>
  );
};

export default OurServicesSection;
