import React from "react";
import styled from "styled-components";

import { hexToRGBA } from "../../../utils/theme";
import CollapsibleList from "../../CollapsibleList";

const StyledFAQSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-top: 70px;
  padding-bottom: 140px;
  padding-left: 10px;
  padding-right: 10px;

  background: ${hexToRGBA('#078FF1', 0.1)};

  .title {
    padding-left: 40px;
    padding-right: 40px;
    margin-bottom: 32px;

    font-family: 'MerriweatherLight';
    font-size: 24px;
    line-height: 30px;
    text-transform: capitalize;
    text-align: center;
    color: #078FF1;
    text-shadow: 1px 1px 0px #FFFFFF;
  }

  .faq-container {
    width: 60%;
  }

  @media ${({ theme }) => theme.devices.laptopM} {
    .faq-container {
      width: 70%;
    }
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    .faq-container {
      width: 80%;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 32px;
    padding-bottom: 32px;

    .title {
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 25px;
    }

    .faq-container {
      width: 100%;
    }
  }
`;

function FAQSection({ items }) {
  return (
    <StyledFAQSection>
      <div className="title">
        <span>Frequently Asked Questions About EINs</span>
      </div>
      <div className="faq-container">
        <CollapsibleList items={items} />
      </div>
    </StyledFAQSection>
  );
}

export default FAQSection;
