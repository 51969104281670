import React from "react";
import styled from "styled-components";

const StyledEntitiesHeaderSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding-left: 15px;
  padding-right: 15px;

  .header-content {
    display: flex;
    flex-direction: column;
    align-items: center;

    margin-top: 2rem;
    margin-bottom: 2rem;

    width: 950px;

    .header-title {
      font-family: 'RobotoBold';
      font-size: 37px;
      line-height: calc(1.42857rem * (37 / 16));
      text-align: center;
      color: #002346;
    }

    .header-text {
      font-family: 'RobotoRegular';
      font-size: 20px;
      line-height: calc(1.42857rem * (20 / 16));
      text-align: center;
      color: #002346;
    }
  }

  .header-hint {
    width: 950px;
    min-height: 62px;
    margin-bottom: 22px;
    padding: 16px;
    background-color: #e0e0e0;

    font-family: 'RobotoBold';
    font-size: 21px;
    line-height: calc(1.42857rem * (21 / 16));

    .header-hint-mobile-text {
      display: none;
    }

    .header-hint-help-icon {
      display: inline-block;
      background: #002346;
      margin-left: 8px;
      width: 22px;
      height: 22px;
      font-family: 'RobotoRegular';
      font-size: 20px;
      text-align: center;
      border-radius: 50%;
      line-height: normal;
      color: #ffffff;
      cursor: pointer;
    }
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    .header-content,
    .header-hint {
      width: 720px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    .header-content,
    .header-hint {
      width: 100%;

      .header-hint-desktop-text {
        display: none;
      }

      .header-hint-mobile-text {
        display: inline;
      }

      .header-hint-help-icon {
        background: none;
        border: 1px solid #002346;
        font-size: 17px;
        color: #002346;
      }
    }
  }
`;

function EntitiesHeaderSection({ onGoToDescriptionClick }) {
  return (
    <StyledEntitiesHeaderSection>
      <div className="header-content">
        <div className="header-title">
          <span>
            Apply For IRS EIN / Federal Tax ID Number
          </span>
        </div>
        <div className="header-text">
          <span>
            Get your Tax ID/EIN Emailed to you Today
          </span>
        </div>
      </div>
      <div className="header-hint">
        <span className="header-hint-desktop-text">
          Select Entity Type Below to Start Your Application
        </span>
        <span className="header-hint-mobile-text">
          Choose Your Entity Type
        </span>
        <span className="header-hint-help-icon" onClick={onGoToDescriptionClick}>?</span>
      </div>
    </StyledEntitiesHeaderSection>
  );
}

export default EntitiesHeaderSection;
