import React, { useCallback, useMemo } from "react";
import styled, { css } from "styled-components";
import getConfig from "next/config";
import { isMobile } from "react-device-detect";
import { OverlayTrigger, Tooltip, Form } from "react-bootstrap";

import { hexToRGBA } from "../../../../utils/theme";

const { publicRuntimeConfig } = getConfig();

const StyledGroup = styled(Form.Group)`
  position: relative;

  padding-left: 0;
  padding-right: 0;

  ${({ withLabel }) =>
    withLabel &&
    css`
      padding-top: 24px;
    `};
`;

const StyledLabel = styled(Form.Label)`
  position: absolute;
  left: 0;
  top: 0;

  font-family: "Aeonik";
  font-weight: 400;
  font-size: 14px;
  line-height: calc(1.42857rem * (14 / 16));
  color: #333333;

  span {
    color: #790000;
  }

  a {
    color: #b1b1b1;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      color: #0a578b;
    }
  }
`;

const StyledControl = styled(Form.Control)`
  resize: none;

  width: 100%;
  min-height: 52px;

  padding-top: 5px;
  padding-bottom: 5px;
  padding-left: 4px;
  padding-right: 4px;

  font-family: "Aeonik";
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #424242;

  border: 1px solid ${hexToRGBA("#000000", 0.1)};
  border-radius: 0;

  background-repeat: no-repeat;
  background-origin: content-box;
  background-position-x: right;

  ${({ isValid }) =>
    isValid &&
    css`
      border: 1px solid #28a745;
      background-image: ${`url(${publicRuntimeConfig.basePath}/icons/V2/validation_success.svg)`};
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    `};

  ${({ isInvalid }) =>
    isInvalid &&
    css`
      border: 1px solid #dc3545;
      background-image: ${`url(${publicRuntimeConfig.basePath}/icons/V2/validation_failure.svg)`};
      background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
    `};

  &:focus {
    background-color: #ffffff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
  }
`;

const StyledFeedback = styled(Form.Control.Feedback)`
  &.processing-feedback,
  &.valid-feedback,
  &.invalid-feedback {
    font-size: 13px;
    line-height: calc(1.42857rem * (13 / 16));
  }

  &.processing-feedback {
    color: ${hexToRGBA("#424242", 0.5)};
  }

  &.valid-feedback {
    color: #198754;
  }

  &.invalid-feedback {
    color: #dc3545;
  }
`;

function Textarea({
  id,
  hint,
  label,
  emptyLabel,
  placeholder,
  required,
  validated,
  isInvalid,
  pattern,
  maxLength,
  defaultInvalidMessage = "",
  validate,
  onKeyPress,
  matchValue,
  value = "",
  onChange,
  ...restProps
}) {
  const handleChange = useCallback(
    (event) => {
      validate && validate(event, { required, matchValue });
      onChange && onChange(event);
    },
    [onChange, validate, required, matchValue]
  );

  const overlayTriggerProps = useMemo(() => {
    const props = {
      placement: isMobile ? "top" : "right-start",
      delay: { show: 0, hide: 0 },
      trigger: ["hover", "focus"],
      overlay: (
        <Tooltip id={`tooltip-${id}`} className="hintTooltip">
          {hint}
        </Tooltip>
      ),
    };

    if (!hint || isMobile) {
      props.show = false;
    }

    return props;
  }, [id, hint]);

  const valid = (!required && validated && !isInvalid) || (value && validated && !isInvalid);
  const invalid = isInvalid || (required && !value && validated);

  return (
    <OverlayTrigger {...overlayTriggerProps}>
      <StyledGroup controlId={id} withLabel={Boolean(label || emptyLabel)}>
        <StyledLabel>
          {label}
          {!emptyLabel && label && required && <span>&nbsp;*</span>}
        </StyledLabel>
        <StyledControl
          as="textarea"
          rows={5}
          name={id}
          required={required}
          placeholder={placeholder}
          pattern={pattern}
          maxLength={maxLength}
          isValid={valid}
          isInvalid={invalid}
          value={value}
          onKeyPress={onKeyPress}
          onChange={handleChange}
          data-hj-allow
          {...restProps}
        />
        <StyledFeedback type="invalid">{defaultInvalidMessage}</StyledFeedback>
      </StyledGroup>
    </OverlayTrigger>
  );
}

export default Textarea;
