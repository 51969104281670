import React, { useEffect } from "react";

import HomePageV1 from "../component/pages/HomePage";
import HomePageV2 from "../V2/components/pages/HomePage";

const V2 = ["taxnumber", "federaltaxid", "reflection-1", "reflection-2", "reflection-3", "landing-1", "landing-2", "landing-3", "landing-4", "landing-5"];

function Home() {
  const isV2 = V2.includes(process.env.NEXT_PUBLIC_REACT_APP_SKIN);

  useEffect(() => {
    localStorage.removeItem("path");
    localStorage.removeItem("product");
  }, []);

  if (isV2) {
    return <HomePageV2 />;
  }

  return <HomePageV1 />;
}

export default Home;
