import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const StyledDisclaimerSection = styled.div`
  padding-top: 26px;
  position: relative;

  &::before {
    content: '';
    display: block;
    position: absolute;

    top: 0;
    left: 0;

    height: 26px;
    width: 100%;

    background: ${`url(${publicRuntimeConfig.basePath}/images/V2/disclaimer.png) no-repeat`};
    background-position-x: left;
    background-position-y: center;
    background-size: cover;
    z-index: -1;
  }

  span {
    display: block;
    padding: 24px;
    
    font-family: 'LatoRegular';
    font-size: 12px;
    line-height: 22px;
    text-align: center;
    color: #1B3858;
  }
`;

function DisclaimerSection() {
  return (
    <StyledDisclaimerSection>
      <span>
        This website is a document preparation & filing service and cannot provide legal, financial, or other professional advice. 
        We do not review the information you submit on a professional, legal or accounting level as we are not, 
        nor are we affiliated with any attorneys or accountants.  We cannot provide any kind of advice, explanation, 
        opinion or recommendation about legal issues, and our communications are not protected by the attorney-client privilege or as work product. 
        For legal advice, please contact an attorney or law firm. 
        Our paid service will process your application for an EIN/Tax ID number (SS-4 Form) with the IRS to obtain your Tax ID Number and deliver it to you quickly and securely via email. The IRS does not supply Tax ID Numbers via e-mail. 
        Our EIN form is simplified for your ease of use, accuracy, and understanding, saving you time. We guarantee you will receive your EIN within 1-3 business days. 
        You can obtain an Employer Identification Number also knowns as an EIN number or Tax ID number without charge with the Internal Revenue Service (“IRS”) but we won’t be able to assist you. 
        This website is not affiliated with the US Department of Treasury, the Internal Revenue Service, or any governmental agency. 
        This product or service has not been approved or endorsed by any governmental agency, and this offer is not being made by an agency of the US government.
      </span>
    </StyledDisclaimerSection>
  );
}

export default DisclaimerSection;
