import styled from "styled-components";

const Button = styled.button`
  padding: 8px 25px;
  width: 100%;
  margin-right: 0;
  border-radius: 0.25rem;
  font-family: NoticiaText;
  font-weight: 700;
  font-size: 18px;
  background: #07437a;
  border: 2px solid #07437a;
  color: #ffffff;
`;

export default Button;
