export const PRODUCTS = [
  {
    code: "EIN_SoleProp",
    path: "EINSoleProp",
    iconSrc: "/icons/V2/sole_proprietor.svg",
    category: "EIN",
    customerName: "Sole Proprietor",
    description: "A sole proprietorship is the only kind of business entity that you don’t have to form with the state. It is a simple entity primarily used by entrepreneurs and freelancers.",
    forSale: true,
  },
  {
    code: "EIN_LLC",
    path: "EINLLC",
    iconSrc: "/icons/V2/llc.svg",
    category: "EIN",
    customerName: "Limited Liability Company (LLC)",
    description: "LLCs are the most common business entity in the U.S., offering flexibility, limited liability protection, and taxation advantages.",
    forSale: true,
  },
  {
    code: "EIN_Partnership",
    path: "EINPartnership",
    iconSrc: "/icons/V2/partnerships.svg",
    category: "EIN",
    customerName: "Partnerships",
    description: "A partnership is an arrangement between two or more individuals to create and manage a business. There are many types of partnerships, run in a variety of ways.",
    forSale: true,
  },
  {
    code: "EIN_CCorp",
    path: "EINCCorp",
    iconSrc: "/icons/V2/corporations.svg",
    category: "EIN",
    customerName: "Corporations",
    description: "A corporation is popular as it offers liability protection, as well as separates the business owner and business as taxable entities.",
    forSale: true,
  },
  {
    code: "EIN_SCorp",
    path: "EINSCorp",
    iconSrc: "/icons/V2/s-corporations.svg",
    category: "EIN",
    customerName: "S-Corporations",
    description: "A type of corporation that draws its name from Subsection S of the tax code. It has all the features of a corporation, but also functions as a pass-through entity for taxation purposes.",
    forSale: true,
  },
  {
    code: "EIN_Trust",
    path: "EINTrust",
    iconSrc: "/icons/V2/trust.svg",
    category: "EIN",
    customerName: "Trusts",
    description: "A type of legal entity in which an individual can place their assets so that they can be passed down to beneficiaries in the future.",
    forSale: true,
  },
  {
    code: "EIN_Estate",
    path: "EINEstate",
    iconSrc: "/icons/V2/estate.svg",
    category: "EIN",
    customerName: "Estate",
    description: "An estate is the sum of any assets left by a deceased individual. That includes things like property, vehicles, and valuables. The will of said individual will determine the administrator of their estate.",
    forSale: true,
  },
  {
    code: "EIN_PSC",
    path: "EINPSC",
    iconSrc: "/icons/V2/personal_service_corporation.svg",
    category: "EIN",
    customerName: "Personal Service Corporation",
    description: "A PSC is a subset of corporations that provides services specifically to individuals. These can include professions like healthcare, law, finance, and more.",
    forSale: true,
  },
  {
    code: "EIN_NonProfit",
    path: "EINNonProfit",
    iconSrc: "/icons/V2/non-profit.svg",
    category: "EIN",
    customerName: "Non-Profit Organization",
    description: "This entity is used by charities who donate their revenue to a specific goal or cause with the goal of benefiting the public instead of generating profit.",
    forSale: true,
  },
  {
    code: "EIN_Church",
    path: "EINChurch",
    iconSrc: "/icons/V2/church.svg",
    category: "EIN",
    customerName: "Church Organization",
    description: "A form of nonprofit that is specifically for religious organizations. And though it is called a “church organization,” it also includes places of worship such as mosques, temples, and synagogues",
    forSale: true,
  },
];

export const INC_PRODUCTS = [
  {
    code: "EIN_LLCIncorporation",
    path: "INCLLC",
    category: "EIN_INC",
    customerName: "LLC Incorporation",
    forSale: true,
  },
];
