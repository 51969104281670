import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 16px;
  padding-right: 16px;

  .section-title {
    margin-bottom: 40px;
    font-family: "Aeonik";
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    color: #07437a;
  }

  .section-content-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 50px;

    .section-content-column {
      display: flex;
      justify-content: center;
      width: calc(50% - 32px);

      &:first-child {
        width: calc(100% - 32px);
      }

      .section-card-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 320px;

        .section-card-image {
          margin-bottom: 16px;

          img {
            width: 100%;
            object-fit: cover;
          }
        }

        .section-card-title {
          margin-bottom: 4px;
          font-family: "Aeonik";
          font-weight: 700;
          font-size: 28px;
          line-height: 32px;
          text-align: center;
          color: #000000;
        }

        .section-card-text {
          margin-bottom: 4px;
          font-family: "Aeonik";
          font-weight: 400;
          font-size: 28px;
          line-height: 32px;
          text-align: center;
          color: #000000;
        }

        .section-card-subtext {
          font-family: "Aeonik";
          font-style: italic;
          font-weight: 400;
          font-size: 18px;
          line-height: 20px;
          text-align: center;
          color: #000000;
        }
      }
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 32px;
    padding-bottom: 32px;

    .section-title {
      margin-bottom: 24px;
      font-size: 38px;
      line-height: 42px;
    }

    .section-content-container {
      flex-direction: column;
      gap: 32px;

      .section-content-column {
        width: 100%;

        &:first-child {
          width: 100%;
        }

        .section-card-container {
          min-width: unset;

          .section-card-image {
            margin-bottom: 16px;
          }

          .section-card-title {
            font-size: 24px;
            line-height: 32px;
          }

          .section-card-text {
            font-size: 16px;
            line-height: 20px;
          }

          .section-card-subtext {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
`;

const AboutTeamSection = () => {
  return (
    <StyleOuterContainer>
      <StyledInnerContainer>
        <div className="section-title">MEET OUR TEAM</div>
        <div className="section-content-container">
          <div className="section-content-column">
            <div className="section-card-container">
              <div className="section-card-image">
                <picture>
                  <source
                    srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/photo-of-owner.png`}
                    type="image/png"
                  />
                  <img
                    src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/photo-of-owner.png`}
                    alt="Background Image"
                  />
                </picture>
              </div>
              <div className="section-card-title">AMANDA BUDAY</div>
              <div className="section-card-text">OWNER</div>
            </div>
          </div>
          <div className="section-content-column">
            <div className="section-card-container">
              <div className="section-card-image">
                <picture>
                  <source
                    srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/photo-of-head-accountant.png`}
                    type="image/png"
                  />
                  <img
                    src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/photo-of-head-accountant.png`}
                    alt="Background Image"
                  />
                </picture>
              </div>
              <div className="section-card-title">KENNETH MARTIN</div>
              <div className="section-card-text">HEAD ACCOUNTANT</div>
              <div className="section-card-subtext">12 YEARS EXPERIENCE</div>
            </div>
          </div>
          <div className="section-content-column">
            <div className="section-card-container">
              <div className="section-card-image">
                <picture>
                  <source
                    srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/photo-of-certified-accountant-1.png`}
                    type="image/png"
                  />
                  <img
                    src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/photo-of-certified-accountant-1.png`}
                    alt="Background Image"
                  />
                </picture>
              </div>
              <div className="section-card-title">ELIZABETH MARSHALLS</div>
              <div className="section-card-text">CERTIFIED ACCOUNTANT</div>
              <div className="section-card-subtext">8 YEARS EXPERIENCE</div>
            </div>
          </div>
          <div className="section-content-column">
            <div className="section-card-container">
              <div className="section-card-image">
                <picture>
                  <source
                    srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/photo-of-certified-accountant-2.png`}
                    type="image/png"
                  />
                  <img
                    src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/photo-of-certified-accountant-2.png`}
                    alt="Background Image"
                  />
                </picture>
              </div>
              <div className="section-card-title">CARLOS ORTIZ</div>
              <div className="section-card-text">CERTIFIED ACCOUNTANT</div>
              <div className="section-card-subtext">7 YEARS EXPERIENCE</div>
            </div>
          </div>
          <div className="section-content-column">
            <div className="section-card-container">
              <div className="section-card-image">
                <picture>
                  <source
                    srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/photo-of-certified-accountant-3.png`}
                    type="image/png"
                  />
                  <img
                    src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/photo-of-certified-accountant-3.png`}
                    alt="Background Image"
                  />
                </picture>
              </div>
              <div className="section-card-title">JOSEPH GARCIA</div>
              <div className="section-card-text">CERTIFIED ACCOUNTANT</div>
              <div className="section-card-subtext">5 YEARS EXPERIENCE</div>
            </div>
          </div>
        </div>
      </StyledInnerContainer>
    </StyleOuterContainer>
  );
};

export default AboutTeamSection;
