import React, { useState, useRef } from "react";
import styled from "styled-components";

import ContactUsForm from "../../forms/ContactUsForm";

const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 16px;
  padding-right: 16px;
  border-top: 1px solid #000000;

  .section-title {
    margin-bottom: 40px;
    font-family: "Aeonik";
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    color: #07437a;
  }

  .section-content-container {
    display: flex;
    justify-content: flex-start;
    width: 80%;
    padding: 50px;
    border: 2px solid #00000080;
    border-radius: 10px;

    .section-content-column {
      width: 50%;

      &:last-child {
        padding-left: 100px;
      }

      .section-content-column-title {
        margin-bottom: 32px;
        font-family: "Aeonik";
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
        text-align: center;
        color: #07437a;
      }

      .section-content-column-text {
        margin-bottom: 4px;
        font-family: "Aeonik";
        font-weight: 700;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }

      .section-content-column-subtext {
        margin-bottom: 4px;
        font-family: "Aeonik";
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #000000;
      }

      .section-content-column-message {
        padding-top: 32px;
        font-family: "Aeonik";
        font-weight: 400;
        font-size: 18px;
        line-height: 22px;
        color: #07437a;
      }
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 32px;
    padding-bottom: 32px;

    .section-title {
      margin-bottom: 24px;
      font-size: 38px;
      line-height: 42px;
    }

    .section-content-container {
      flex-direction: column;
      gap: 32px;
      width: 100%;
      padding: 16px;

      .section-content-column {
        width: 100%;

        &:last-child {
          padding-left: unset;
        }

        .section-content-column-title {
          margin-bottom: 16px;
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
`;

const ContactUsSection = () => {
  const ref = useRef(null);
  const [values, setValues] = useState({ validated: false, loading: false, submitted: false });

  const handleChange = (e) => {
    const { name, value } = e.target || {};
    setValues((prevValues) => ({ ...prevValues, validated: false, [name]: value, submitted: false }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    if (form.checkValidity() === false) {
      document.querySelector(".form-control:invalid")?.scrollIntoView({ behavior: "smooth", block: "start" });
    } else {
      setValues((prevValues) => ({ ...prevValues, loading: true, submitted: false }));
      ref.current = setTimeout(() => {
        setValues((prevValues) => ({ ...prevValues, submitted: true, loading: false }));
      }, 2000);
    }

    setValues((prevValues) => ({ ...prevValues, validated: true }));
  };

  return (
    <StyleOuterContainer>
      <StyledInnerContainer>
        <div className="section-title">QUESTIONS? CONTACT US</div>
        <div className="section-content-container">
          <div className="section-content-column">
            <div className="section-content-column-title">Send us a message</div>
            <ContactUsForm values={values} onChange={handleChange} onSubmit={handleSubmit} />
            {values.submitted && <div className="section-content-column-message">Message has been sent</div>}
          </div>
          <div className="section-content-column">
            <div className="section-content-column-title">Hours</div>
            <div className="section-content-column-text">Mon</div>
            <div className="section-content-column-subtext">09:00 am – 05:00 pm</div>
            <div className="section-content-column-text">Tue</div>
            <div className="section-content-column-subtext">09:00 am – 05:00 pm</div>
            <div className="section-content-column-text">Wed</div>
            <div className="section-content-column-subtext">09:00 am – 05:00 pm</div>
            <div className="section-content-column-text">Thu</div>
            <div className="section-content-column-subtext">09:00 am – 05:00 pm</div>
            <div className="section-content-column-text">Fri</div>
            <div className="section-content-column-subtext">09:00 am – 05:00 pm</div>
            <div className="section-content-column-text">Sat</div>
            <div className="section-content-column-subtext">Closed</div>
            <div className="section-content-column-text">Sun</div>
            <div className="section-content-column-subtext">Closed</div>
          </div>
        </div>
      </StyledInnerContainer>
    </StyleOuterContainer>
  );
};

export default ContactUsSection;
