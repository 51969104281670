import React from "react";
import styled from "styled-components";
import router, { useRouter } from "next/router";

const productsMap = new Map([
  ["/products/EINSoleProp", "Sole Proprietor / Individual"],
  ["/products/EINPartnership", "Partnership"],
  ["/products/EINCCorp", "Corporation"],
  ["/products/EINSCorp", "S-Corporation"],
  ["/products/EINEstate", "Estate of Deceased Individual"],
  ["/products/EINNonProfit", "Non-Profit Organization"],
  ["/products/EINLLC", "Limited Liability Company"],
  ["/products/EINTrust", "Trust"],
  ["/products/EINPSC", "Personal Service Corporation"],
  ["/products/EINChurch", "Church Organization"],
]);

const StyledEntitiesHeaderSection = styled.div`
  width: 100%;
  padding-left: 15px;
  padding-right: 15px;
  line-height: 1.42857;

  .headSection {
    display: flex;
    flex-flow: column nowrap;
    margin: 0 0 24px;
  }
  
  .callToAction__main {
    font-size: 50px;
    font-family: RobotoBold;
    color: #0097e6;
    line-height: 1;
  }
  .callToAction__details-first {
    font-size: 20px;
    font-family: RobotoBold;
    color: #055393;
  }
  .callToAction__details-first .hl {
    font-weight: 900;
    color: #d52d27;
  }
  .callToAction__details-second {
    color: #39393d;
    font-size: 16px;
    font-family: Myriad Pro, RobotoRegular, sans-serif;
  }
  .callToAction__details-second .hl {
    font-family: RobotoBold;
  }
  .formSelection {
    flex: 0 1 auto;
    margin-top: 16px;
  }

  .formSelection__tip {
    position: relative;
    max-width: 420px;
    margin-bottom: 30px;
    padding: 8px 16px;
    color: #d52d27;
    background-color: #fff;
    border: 1px solid #d52d27;
    box-shadow: 4px 4px 9px 0 rgba(0, 0, 0, .3);
  }
  .formSelection__tip b {
    margin-right: 16px;
    font-size: 21px;
    font-family: RobotoBold;
    text-transform: uppercase;
  }
  .formSelection__tip span {
    font-size: 20px;
    font-family: RobotoRegular;
    border-bottom: 1px solid currentColor;
  }

  .formSelection__tip:after {
    content: "";
    position: absolute;
    left: 28px;
    bottom: -10px;
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-top: 1px solid;
    border-right: 1px solid;
    transform: rotate(135deg);
  }
  .formSelection__inner {
    position: relative;
  }

  .formSelection__inner:after {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -13px;
    border: 14px solid transparent;
    border-top: 24px solid #fff;
    pointer-events: none;
    content: "";
  }

  .formSelection select {
    width: 100%;
    padding: 12px 44px 16px 16px !important;
    color: #fff;
    font-size: 25px !important;
    background-color: #ff9331;
    border-color: #ff9331;
    border-radius: 8px;
    cursor: pointer;
    -webkit-appearance: none;
  }
  
  .formSelection select:focus {
    padding: 12px 44px 16px 16px !important;
    color: #fff;
    font-size: 25px !important;
    background-color: #ff9331;
    border-color: #ff9331;
    outline: 0;
    font-family: 'RobotoRegular';
  }
  .formSelection__inner:before {
    position: absolute;
    top: 0;
    right: 0;
    width: 68px;
    height: 100%;
    background-color: #f37c11;
    border-color: #f37c11;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    pointer-events: none;
    content: "";
  }
  @media (min-width: 768px) {
    .formSelection__tip span {
      font-size: 24px;
    }
    .callToAction__details-first {
      font-size: 30px;
    }
    .callToAction__main {
      font-size: 96px;
    }
    .callToAction__details-second {
      font-size: 20px;
    }
  }
  @media (min-width: 1024px) {
    .formSelection {
      flex: 0 1 500px;
    }
    .formSelection__tip {
      background-color: #d52d27;
      color: #fff;
    }
    .formSelection__inner:before {
      display: none;
    }
    .formSelection__tip:after {
      bottom: -32px;
      width: auto;
      height: auto;
      background-color: hsla(0, 0%, 100%, 0);
      border: 16px solid transparent;
      border-top: 16px solid #d52d27;
      transform: rotate(0);
    }
    .formSelection select {
      color: #0097e6;
      background-color: #fff;
      border-color: #0097e6;
    }
    .formSelection select:focus {
      color: #0097e6;
      background-color: #fff;
      border-color: #0097e6;
    }
    .formSelection__inner:after {
      width: 16px;
      height: 16px;
      border: none;
      border-top: 1px solid #0097e6;
      border-right: 1px solid #0097e6;
      transform: rotate(135deg);
    }
    .headSection {
      flex-flow: row nowrap;
      justify-content: space-between;
      margin: 90px 0;
    }
    .callToAction {
      flex: 0 0 560px;
    }
  }
  @media (min-width: 768px) {
    width: 750px;
  }
  @media (min-width: 992px) {
    width: 970px;
  }

  @media (min-width: 1024px) {
    width: 990px;
  }
  @media (min-width: 1200px) {
    width: 1170px;
  }
  @media (min-width: 1280px) {
    padding-left: 40px;
    padding-right: 40px;
    width: 1170px;
  }
  @media (min-width: 1600px) {
    width: 1440px;
    padding-left: 115px;
    padding-right: 115px;
  }
`;

function EntitiesHeaderSection() {
  const router = useRouter();
  return (
    <StyledEntitiesHeaderSection>
        <div className="headSection">
          <div className="callToAction">
            <div className="callToAction__main">GET YOUR EIN ONLINE</div>
            <div className="callToAction__details-first"><span className="hl">FAST!</span> Emailed to you in 60 minutes
            </div>
            <div className="callToAction__details-second">Your application will be <span className="hl">reviewed by trained specialist</span> to
              make sure it <span className="hl">complies with IRS standards</span></div>
          </div>
          <div id="getStarted" className="formSelection">
            <div className="formSelection__tip"><b>Get Started</b><span>Takes 2-3 minutes</span></div>
            <div className="formSelection__inner">
              <select onChange={(e) => router.push(e.target.value)}>
              <option value="" disabled="">Select Entity Type</option>
              {Array.from(productsMap.keys()).map(route => (
                <option key={route} value={route}>
                    {productsMap.get(route)}
                </option>
              ))}
            </select></div>
          </div>
        </div>
    </StyledEntitiesHeaderSection>
  );
}

export default EntitiesHeaderSection;
