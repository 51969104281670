import React from "react";
import styled from "styled-components";
import Link from "next/link";

import ProductCard from "../../ProductCard";

const StyledBusinessEntitiesSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  .product-cards {
    display: flex;
    flex-wrap: wrap;

    width: 980px;

    padding-left: 15px;
    padding-right: 15px;

    .product-card-container {
      width: 50%;

      margin-bottom: 16px;

      &:nth-child(even) {
        padding-left: 8px;
      }

      &:nth-child(odd) {
        padding-right: 8px;
      }
    }
  }

  @media ${({ theme }) => theme.devices.laptopS} {
    .product-cards {
      width: 720px;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    .product-cards {
      width: 100%;

      .product-card-container {
        width: 100%;
        
        &:nth-child(even) {
          padding-left: 0;
        }

        &:nth-child(odd) {
          padding-right: 0;
        }
      }
    }
  }
`;

function BusinessEntitiesSection({ products }) {
  return (
    <StyledBusinessEntitiesSection>
      <div className="product-cards">
        {products.map(({ code, path, iconSrc, customerName, shortDescription, popular }) => (
          <Link key={code} href={`/products/${path}`} passHref>
            <div className="product-card-container">
              <ProductCard
                id={code}
                iconSrc={iconSrc}
                title={customerName}
                description={shortDescription}
                popular={popular}
              />
            </div>
          </Link>
        ))}
      </div>
    </StyledBusinessEntitiesSection>
  );
}

export default BusinessEntitiesSection;
