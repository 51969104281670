import React, { useMemo } from "react";
import styled from "styled-components";
import getConfig from "next/config";
import { isMobile } from "react-device-detect";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { hexToRGBA } from "../../../utils/theme";

const { publicRuntimeConfig } = getConfig();

const StyledProductCard = styled.div`
  display: flex;
  align-items: flex-start;

  height: 100%;
  width: 100%;

  padding-top: ${({ popular }) => popular ? '34px' : '24px'};
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;

  box-shadow: 3px 3px 4px 0 ${hexToRGBA('#424242', 0.3)};
  border: 1px solid ${hexToRGBA('#424242', 0.3)};

  cursor: pointer;

  position: relative;

  &:hover {
    border: 1px solid ${hexToRGBA('#0a578b', 0.7)};
    box-shadow: 1px 1px 5px ${hexToRGBA('#424242', 0.3)};
  }

  :after {
    position: absolute;
    text-align: left;
    top: -1px;
    right: -1px;
    display: ${({ popular }) => popular ? 'block' : 'none'};
    padding: 2px 14px;
    color: #fff;
    font-family: 'InterRegular';
    font-size: 12px;
    line-height: calc(1.42857rem * (12 / 16));
    background-color: #0a578b;
    content: "MOST POPULAR";
  }

  .product-card-icon {
    display: flex;
    align-items: flex-start;

    height: 100%;
    margin-right: 16px;

    img {
      height: 40px;
      width: 40px;
    }
  }

  .product-card-content {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    
    width: 100%;

    .product-card-title {
      display: flex;
      justify-content: flex-start;

      font-family: 'InterSemiBold';
      font-size: 22px;
      line-height: calc(1.42857rem * (22 / 16));
      color: #0a578b;
    }

    .product-card-description {
      display: flex;
      justify-content: flex-start;
      flex-grow: 1;
      
      font-family: 'InterRegular';
      font-size: 14px;
      color: #424242;
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-left: 20px;
    padding-right: 20px;

    :after {
      width: 100%;
      left: -1px;
      text-align: center;
    }
  }
`;

function ProductCard({ id, iconSrc, title, description, popular }) {
  const overlayTriggerProps = useMemo(() => {
    const props = {
      key: id,
      placement: isMobile ? "top" : "right",
      delay: { show: 0, hide: 0 },
      trigger: ["hover", "focus"],
      overlay: (
        <Tooltip id={`tooltip-${id}`}>
          {description}
        </Tooltip>
      ),
      show: false,
    };

    return props;
  }, [id, description]);

  return (
    <OverlayTrigger {...overlayTriggerProps}>
      <StyledProductCard tabIndex={0} popular={popular}>
        <div className="product-card-icon">
          <img
            src={`${publicRuntimeConfig.basePath}/${iconSrc}`}
            alt=""
            layout="fill"
          />
        </div>
        <div className="product-card-content">
          <div className="product-card-title">
            <span>{title}</span>
          </div>
          <div className="product-card-description">
            <span>{description}</span>
          </div>
        </div>
      </StyledProductCard>
    </OverlayTrigger>
  );
}

export default ProductCard;
