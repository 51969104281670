import React from "react";
import styled from "styled-components";
import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

const StyleOuterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledInnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1444px;
  width: 100%;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 16px;
  padding-right: 16px;

  .section-title {
    margin-bottom: 40px;
    font-family: "Aeonik";
    font-weight: 700;
    font-size: 48px;
    line-height: 55px;
    text-align: center;
    color: #07437a;
  }

  .section-content-container {
    display: flex;
    justify-content: center;
    gap: 100px;

    .section-card-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 442px;

      .section-card-image {
        margin-bottom: 24px;

        img {
          width: 100%;
          object-fit: cover;
        }
      }

      .section-card-title {
        margin-bottom: 16px;
        font-family: "Aeonik";
        font-weight: 700;
        font-size: 28px;
        line-height: 32px;
        text-align: center;
        color: #07437a;
      }

      .section-card-text {
        font-family: "Aeonik";
        font-weight: 400;
        font-size: 18px;
        line-height: 20px;
        text-align: center;
        color: #07437a;
      }
    }
  }

  @media ${({ theme }) => theme.devices.tablet} {
    padding-top: 32px;
    padding-bottom: 32px;

    .section-title {
      margin-bottom: 24px;
      font-size: 38px;
      line-height: 42px;
    }

    .section-content-container {
      flex-direction: column;
      gap: 32px;

      .section-card-container {
        min-height: unset;

        .section-card-image {
          margin-bottom: 16px;
        }

        .section-card-title {
          margin-bottom: 8px;
          font-size: 24px;
          line-height: 32px;
        }

        .section-card-text {
          font-size: 16px;
          line-height: 20px;
        }
      }
    }
  }
`;

const AboutFilingSection = () => {
  return (
    <StyleOuterContainer>
      <StyledInnerContainer>
        <div className="section-title">About Easy Online E-Tax Filing</div>
        <div className="section-content-container">
          <div className="section-card-container">
            <div className="section-card-image">
              <picture>
                <source
                  srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/years-of-experience.png`}
                  type="image/png"
                />
                <img
                  src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/years-of-experience.png`}
                  alt="Background Image"
                />
              </picture>
            </div>
            <div className="section-card-title">Years of Experience</div>
            <div className="section-card-text">
              Our team has 10+ years of experience. We make sure your taxes are filed accurately and correctly.
            </div>
          </div>
          <div className="section-card-container">
            <div className="section-card-image">
              <picture>
                <source
                  srcSet={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/official-e-file-provider.png`}
                  type="image/png"
                />
                <img
                  src={`${publicRuntimeConfig.basePath}/images/V2/V2-landing-1/official-e-file-provider.png`}
                  alt="Background Image"
                />
              </picture>
            </div>
            <div className="section-card-title">Official e-File Provider of IRS</div>
            <div className="section-card-text">
              We are authorized by the IRS to ensure accuracy when filing your taxes with the state.
            </div>
          </div>
        </div>
      </StyledInnerContainer>
    </StyleOuterContainer>
  );
};

export default AboutFilingSection;
