import React, { Component } from "react";
const basePath = process.env.NEXT_PUBLIC_BASE_PATH;
class ProductIconSkin extends Component {
  render() {
    if (!this.props.code) {
      return <div>loading</div>;
    } else {
      if (this.props.code === "EINSoleProp")
        return (
          <div className={"taxid-round-icon "}>
            <img src={`${basePath}/images/sole.png`} alt="" />
          </div>
        );
      if (this.props.code === "EINLLC")
        return (
          <div className={"taxid-round-icon "}>
            <img src={`${basePath}/images/llc.png`} alt="" />
          </div>
        );
      if (this.props.code === "EINTrust")
        return (
          <div className={"taxid-round-icon "}>
            <img src={`${basePath}/images/trust.png`} alt="" />
          </div>
        );
      if (this.props.code === "EINEstate")
        return (
          <div className={"taxid-round-icon "}>
            <img src={`${basePath}/images/estate.png`} alt="" />
          </div>
        );
      if (this.props.code === "EINNonProfit")
        return (
          <div className={"taxid-round-icon "}>
            <img src={`${basePath}/images/nonprofit.png`} alt="" />
          </div>
        );
      if (this.props.code === "EINChurch")
        return (
          <div className={"taxid-round-icon "}>
            <img src={`${basePath}/images/church.png`} alt="" />
          </div>
        );
      if (this.props.code === "EINPartnership")
        return (
          <div className={"taxid-round-icon "}>
            <img src={`${basePath}/images/partherships.png`} alt="" />
          </div>
        );
      if (this.props.code === "EINPSC")
        return (
          <div className={"taxid-round-icon "}>
            <img src={`${basePath}/images/personal-service.png`} alt="" />
          </div>
        );
      if (this.props.code === "EINCCorp")
        return (
          <div className={"taxid-round-icon "}>
            <img src={`${basePath}/images/corp.png`} alt="" />
          </div>
        );
      if (this.props.code === "EINSCorp")
        return (
          <div className={"taxid-round-icon "}>
            <img src={`${basePath}/images/s-corp.png`} alt="" />
          </div>
        );
    }
    return (
      <>
        <h1>Hello</h1>
      </>
    );
  }
}

export default ProductIconSkin;
